import createClient from './axiosClient'
import socketManager from './socket-manager'

export class BaseAPIProvider {
	constructor() {
		this.axios = this.getClient()
	}

	getBaseURL() {
		return '/'
	}

	getClient() {
		return createClient(this.getBaseURL())
	}
}

export class BaseAPIModelProvider extends BaseAPIProvider {
	parseInstance(instance) {
		return instance
	}

	list(sort, limit, skip, fields) {
		let params = {  }
		if (sort) {
			params.sort = sort
		}
		if (limit) {
			params.limit = limit
		}
		if (skip) {
			params.skip = skip
		}
		if (fields) {
			params.fields = fields
		}
		return this.axios.get('', { params }).then((res) => res.map(this.parseInstance))
	}

	filter(query, sort, limit, skip, fields) {
		let params = { q: JSON.stringify(query) }
		if (sort) {
			params.sort = sort
		}
		if (limit) {
			params.limit = limit
		}
		if (skip) {
			params.skip = skip
		}
		if (fields) {
			params.fields = fields
		}
		return this.axios.get('', { params }).then((res) => res.map(this.parseInstance))
	}

	get(id) {
		return this.axios.get(`/${id}`).then(this.parseInstance)
	}

	async subscribe(id, currentValues, callback) {
		callback(this.parseInstance(currentValues));
		
		const room = this.getBaseURL() + '/' + id;
		return socketManager.addListener(room, (data) => {
			currentValues = {
				...currentValues,
				...data
			};
			callback(this.parseInstance(currentValues));
		})

	}

	async fetchAndSubscribe(id, callback) {
		let currentValues = await this.get(id);
		return this.subscribe(id, currentValues, callback);
	}

	schema() {
		return this.axios.get('/schema')
	}

	create(props) {
		return this.axios.post('', props).then(this.parseInstance)
	}

	update(id, props) {
		return this.axios.put(`/${id}`, props).then(this.parseInstance).then((res) => {
			const listeners = socketManager.getListeners(this.getBaseURL() + '/' + id) || [];
			Object.values(listeners).forEach((listener) => listener(res));
			return res
		});
	}

	delete(id) {
		return this.axios.delete(`/${id}`)
	}

	deleteMany(query) {
		return this.axios.delete('', { data: query });
	}
}
