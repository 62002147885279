import React, { useState, useEffect } from 'react';
import { generateElementFunction } from './generateElement';
import { cn } from '@/lib/utils';
import { toCamelCase } from './utils';

function createComponentImports(appSDK, componentName) {
  let entitiesImportObject = {};
  Object.keys(appSDK.entities).forEach(entityName => {
    entitiesImportObject[`@/entities/${entityName}`] = appSDK.entities;
    entitiesImportObject[`./entities/${entityName}`] = appSDK.entities;
  });
  entitiesImportObject = {
    './entities': appSDK.entities,
    '@/entities': appSDK.entities,
    '@/entities/': appSDK.entities,
    '@/entities/all': appSDK.entities,
    ...entitiesImportObject,
  };

  let integrationsImportObject = {};
  Object.keys(appSDK.integrations).forEach(integrationPkgName => {
    integrationsImportObject[`@/integrations/${integrationPkgName}`] = appSDK.integrations[integrationPkgName];
    integrationsImportObject[`./integrations/${integrationPkgName}`] = appSDK.integrations[integrationPkgName];
  });

  const utils = {
    createPageUrl: appSDK.createPageUrl,
  }
  
  let componentsImportObject = {};
  let componentsObject = {};
  Object.keys(appSDK.files.components).forEach(compName => {
    const componentFunc = (props) => <ComponentPreview componentCode={appSDK.files.components[compName]} componentProps={props} componentName={compName} appSDK={appSDK} />
    // ugly hack to get components that are not exported by default: 
    componentFunc[toCamelCase(compName)] = componentFunc;
    componentsImportObject[`@/components/${compName}`] = componentFunc;
    componentsImportObject[`./components/${compName}`] = componentFunc;
    componentsImportObject[`../components/${compName}`] = componentFunc;
    componentsImportObject[`../${compName}`] = componentFunc;
    componentsImportObject[`./${compName}`] = componentFunc;
    // relative imports, if they are in the same directory:
    const currentDir = componentName.split('/').slice(0, -1).join('/');
    const componentDir = compName.split('/').slice(0, -1).join('/');
    if (currentDir === componentDir) {
      const componentNameLastPart = compName.split('/').pop();
      componentsImportObject[`./${componentNameLastPart}`] = componentFunc;
    }
    
    componentsObject[compName] = componentFunc;
  });

  componentsImportObject[`@/components`] = componentsObject;
  componentsImportObject[`./components`] = componentsObject;

  return {
    ...entitiesImportObject,
    ...integrationsImportObject,
    ...componentsImportObject,
    '@/utils': utils,
    './utils': utils,
  }
  
}

export default function ComponentPreview({ componentCode, componentProps, componentName, appSDK }) {    
  const [elementFunction, setElementFunction] = useState(null);

  const createError = (e) => {
    console.log("CREATING ERROR FROM COMPNENT PREVIEW WITH PREVIEW NAME:", componentName, e);
    return {
      title: e.message || e.title || e.toString(),
      details: e.details || e.stack || e.toString(),
      componentName: componentName,
    }
  }

  const createConsole = () => {
    return {
      log: (...args) => {
        console.log("Component Logger:", ...args);
      },
      error: (...args) => {
        console.error("Component Logger:", ...args);
        console.log("CALLING FROM CONSOLE ERROR", createError(args.map(arg => String(arg)).join(' ')));
        appSDK.options.onError(...Object.values(createError(args.map(arg => String(arg)).join(' '))));
      },
      warn: (...args) => {
        console.warn("Component Logger:", ...args);
      }

    }
  }

  const createGlobals = () => {
    return {
      console: createConsole(),
      cn: cn
    }
  }


  const _generateElement = () => {
    try {
      const imports = createComponentImports(appSDK, componentName);
      const elementFunction = generateElementFunction(componentCode, imports, createGlobals());
      // weird bug in react, need to wrap in an object
      setElementFunction({"elementFunction":elementFunction});
    } catch (e) {
      console.log("Error generating element", e);
      handleError(e);
    }
  }

  const handleError = (e) => {
    const details = e.error?.stack || e.error?.details || e.details;
    console.log("CALLING FROM HANDLE ERROR at", componentName, e, appSDK.options);
    appSDK.options.onError(e.message, details, componentName);
  }

  useEffect(() => {
    window.addEventListener('error', handleError);
    try {
      _generateElement();
    } catch (e) {
      console.log("Error generating element", e);
      handleError(e);
    }
    return () => {
      window.removeEventListener('error', handleError);
    }
  }, [componentCode, window.location.href]);


    if (!elementFunction || !elementFunction.elementFunction) {
      return <></>;
    }
    const elementCompiled = elementFunction.elementFunction(componentProps);
    return elementCompiled;
  }