import { useState } from 'react';
import EditorSidebar from './EditorSidebar';
import { useApp } from '../../context/AppContext';
import AppPage from './editor-subpages/AppPage';
import AppEntity from './editor-subpages/AppEntity';
import AppUsers from './editor-subpages/AppUsers';
import AppLogoSettings from './editor-subpages/AppLogoSettings';
import AppSettings from './editor-subpages/AppSettings';
import AppAPI from './editor-subpages/AppAPI';
import AppOverview from './editor-subpages/AppOverview';
import AppProductFlows from './editor-subpages/AppProductFlows';
import AppLogs from './editor-subpages/AppLogs';
import { useParams, useNavigate } from 'react-router-dom';
import { PanelLeft } from 'lucide-react';
import AppAnalyticsPage from './editor-subpages/AppAnalyticsPage';
import AppIDE from '@/components/user-app/ide/AppIDE';

export default function EditorWorkspace() {
    const {app} = useApp();
    const {workspaceView} = useParams();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    
    function getElementComponent(view) {
        if (view === "overview") {
            return <AppOverview />;
        } else if (view === "analytics") {
            return <AppAnalyticsPage />;
        } else if (view.startsWith("page-")) {
            const pageId = view.split("page-")[1];
            return <div>Coming soon...</div>
            // const page = app.pages.find(page => page.id === pageId);
            // return <AppPage page={null} />;
        } else if (view.startsWith("entity-")) {
            const entityId = view.split("entity-")[1];
            const entity = app.entities[entityId];
            return <AppEntity entity={entity} />;
        } else if (view === "users") {
            return <AppUsers />;
        } else if (view === "logo") {
            return <AppLogoSettings />;
        } else if (view === "app-settings") {
            return <AppSettings />;
        } else if (view === "api") {
            return <AppAPI />;
        } else if (view === "product-flows") {
            return <AppProductFlows />;
        } else if (view === "logs") {
            return <AppLogs />;
        } else if (view === "code") {
            return <AppIDE />;
        }
        return null;
    }

    const onSelect = (itemId) => {
        navigate(`/apps/${app.id}/editor/workspace/${itemId}`);
    }

    const sidebarItems = [
        {
            id: 'overview',
            label: 'Overview',
            icon: '📋'
        },
        {
            id: 'analytics',
            label: 'Analytics',
            icon: '📈'
        },
        // {
        //     id: 'pages',
        //     label: 'Pages',
        //     icon: '📄',
        //     subitems: Object.keys(app.pages).map(pageName => ({
        //         id: `page-${pageName}`,
        //         label: pageName,
        //         icon: null
        //     }))
        // },
        {
            id: 'data',
            label: 'Data',
            icon: '💾',
            subitems: [
                ...Object.keys(app.entities).map(entity => ({
                    id: `entity-${entity}`,
                    label: entity
                }))
            ]
        },
        {
            id: 'code',
            label: 'Code',
            icon: '💻',
        },
        {
            id: 'users',
            label: 'User Management',
            icon: '👥'
        },
        {
            id: 'logs',
            label: 'Logs',
            icon: '🔍'
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: '⚙️',
            subitems: [
                {
                    id: 'logo',
                    label: 'Logo',
                    // icon: '🖼️'
                },
                {
                    id: 'app-settings',
                    label: 'App Settings',
                    // icon: '📝'
                },
                {
                    id: 'api',
                    label: 'API',
                    // icon: '🔌'
                }
            ]
        }
    ];

    return (
        <div className="flex h-full w-full relative">
            {/* Mobile menu button */}
            <button
                className="lg:hidden absolute top-4 left-4 z-30 p-2 rounded-md bg-gray-100 hover:bg-gray-200 transition-colors"
                onClick={() => setIsSidebarOpen(true)}
            >
                <PanelLeft size={20} />
            </button>

            <EditorSidebar 
                items={sidebarItems}
                selected={workspaceView || 'overview'}
                onSelect={(itemId) => {
                    onSelect(itemId);
                    setIsSidebarOpen(false);
                }}
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
            />
            
            <div className="flex-1 overflow-y-auto bg-white">
                <div className="lg:p-4 mt-16 lg:mt-0">
                    {getElementComponent(workspaceView || 'overview')}
                </div>
            </div>
        </div>
    );
}
