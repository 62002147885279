import { BaseAPIModelProvider } from "../base"

export default function createUsersSDK(appId, onError) {
    class _UsersSDK extends BaseAPIModelProvider {
        getBaseURL() {
            return `/apps/${appId}`
        }

        registerUser() {
            return this.axios.post(`/users/register`)
        }
    
        inviteUser(userEmail, role) {
            return this.axios.post(`/users/invite-user`, { user_email: userEmail, role })
        }
    
        updateUserRole(userEmail, role) {
            return this.axios.post(`/users/update-user-role`, { user_email: userEmail, role })
        }
    
        getMyUserInfo() {
            return this.axios.get(`/entities/User/me`)
        }
    }
    return new _UsersSDK(onError);
}