import Home from "./home/HomePage";
import UserSettings from "./UserSettings";
import UserApps from "./UserApps";
import { Home as HomeIcon, Edit, Settings, Code, MessageSquare, Layers, Plug2, Plus, CreditCard, Users, BarChart } from 'lucide-react';
import UserAppEditor from "./app-editor/UserAppEditor";
import AppDemo from "../components/user-app/demo/AppDemo";
import { AppProvider } from "../context/AppContext";
import CreateAppPage from "./CreateApp";
import Billing from "./billing/Billing";
import UserManagement from "./admin/UserManagement";
import AdminAnalytics from "./admin/AdminAnalytics";
import AppLoginPage from "./AppLoginPage";


function AppDemoWrapper() {
    // return <AppProvider>
    //     <div className="min-h-screen w-full relative">
    //         <AppDemo />
    //     </div>
    // </AppProvider>;

    return <div className="min-h-screen w-full relative">
        <AppDemo />
    </div>
    
}

function ProxyPageComponent({urlChangeHandler}) {
    const currentUrl = window.location.pathname;
    const newUrl = urlChangeHandler(currentUrl);
    console.log("ProxyPageComponent", currentUrl, newUrl);
    if (newUrl !== currentUrl) {
        window.location.href = newUrl;
    }
    return <div>Loading...</div>;
}

const pages = [
    { 
        name: 'Home', 
        url: '/', 
        component: <Home />, 
        icon: <HomeIcon /> 
    },
    {
        name: 'User Apps', 
        url: '/user-apps/:appId/show/:pageName?', 
        component: <ProxyPageComponent urlChangeHandler={(url) => url.replace('/user-apps/', '/apps/')} />, 
        icon: <Layers />,
        showInMenu: false
    },
    { 
        name: 'User Apps', url: '/apps', component: <UserApps />, icon: <Layers />,
        children: [
            {
                name: 'User App Dashboard', 
                url: '/apps/:appId', 
                component: <UserAppEditor />, 
                icon: <Layers />,
            },
            {
                name: 'App Login',
                url: '/apps/:appId/login',
                component: <AppLoginPage />,
                usesLayout: false
            },
            { 
                name: 'Show User App', 
                url: '/apps/:appId/show/:pageName?', 
                component: <AppDemoWrapper />, 
                icon: <Code />,
                usesLayout: false
            },
            {
                name: "App Show",
                url: "/apps-show/:appSlug/:pageName?",
                component: <AppDemoWrapper />,
                icon: <Code />,
                usesLayout: false
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview/:pageName', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/dashboard', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/preview', 
                component: <UserAppEditor view="preview" />, 
                icon: <Edit />,
            },
            { 
                name: 'Edit User App', 
                url: '/apps/:appId/editor/workspace/:workspaceView', 
                component: <UserAppEditor view="workspace" />, 
                icon: <Edit />,
            },
            {
                name: 'Create App',
                url: '/create-app',
                component: <CreateAppPage />,
                icon: <Plus />,
            }
        ]
    },
    {
        name: 'Billing',
        url: '/billing',
        component: <Billing />,
        icon: <CreditCard />,
        hide: true
    },
    { 
        name: 'User Settings', 
        url: '/user-settings',
        hide: true,
        component: <UserSettings />,
        icon: <Settings /> 
    },
    {
        name: 'User Management',
        url: '/user-management',
        component: <UserManagement />,
        icon: <Users />,
        hide: true
    },
    {
        name: 'Analytics',
        url: '/admin/analytics',
        component: <AdminAnalytics />,
        icon: <BarChart />,
        hide: true
    }
    // {
    //     name: 'Integrations',
    //     url: '/integrations',
    //     component: <IntegrationsPage />,
    //     icon: <Plug2 /> 
    // }
]

  
export {
    pages
};