import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppWindow, MoreHorizontal, Search, SortAsc, SortDesc, Star } from 'lucide-react';
import UserAppAPI from '../api/UserAppAPI';
import moment from 'moment';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Separator } from "@/components/ui/separator";
import { useAuth } from '@/context/AuthContext';

function UserApps() {
    const { user } = useAuth();
    const [userApps, setUserApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState('desc');
    const [filterMine, setFilterMine] = useState(false);
    const [filterStarred, setFilterStarred] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const appsPerPage = 24;
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserApps();
    }, [currentPage, sortOrder, filterMine]);

    const fetchUserApps = async () => {
        try {
            const sortPrefix = sortOrder === 'desc' ? '-' : '';
            const query = filterMine ? { created_by: user.email } : {};
            const apps = await UserAppAPI.filter(
                query,
                `${sortPrefix}updated_date`, 
                appsPerPage, // limit
                (currentPage - 1) * appsPerPage,  // skip
                ['id', 'name', 'logo_url', 'user_description', 'created_by', 'created_date', 'updated_date', 'is_starred'].join(','),
            );
            setUserApps(apps);
            setHasMore(apps.length === appsPerPage);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch user apps:', error);
            setLoading(false);
        }
    };

    const handleAppClick = (appId) => {
        navigate(`/apps/${appId}/editor/preview`);
    };

    const filteredApps = userApps
        .filter(app => {
            const matchesSearch = app.name.toLowerCase().includes(search.toLowerCase()) ||
                                (app.user_description || '').toLowerCase().includes(search.toLowerCase());
            const matchesStarred = !filterStarred || app.is_starred;
            return matchesSearch && matchesStarred;
        });

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
            </div>
        );
    }

    return (
        <div className="p-6 bg-background min-h-full">
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
                    <h2 className="text-3xl font-bold">Your Apps</h2>
                    
                    <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
                        <div className="relative">
                            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-muted-foreground h-4 w-4" />
                            <Input
                                type="text"
                                placeholder="Search apps..."
                                className="pl-10 w-full sm:w-80"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>

                        <div className="flex gap-2">
                            <Button
                                variant={filterMine ? "default" : "outline"}
                                onClick={() => setFilterMine(!filterMine)}
                            >
                                {filterMine ? 'My Apps' : 'All Apps'}
                            </Button>

                            <Button
                                variant={filterStarred ? "default" : "outline"}
                                onClick={() => setFilterStarred(!filterStarred)}
                            >
                                <Star className={`h-4 w-4 ${filterStarred ? 'fill-current' : ''}`} />
                            </Button>

                            <Button
                                variant="outline"
                                onClick={() => {
                                    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
                                    setCurrentPage(1);
                                }}
                            >
                                {sortOrder === 'desc' ? <SortDesc className="h-4 w-4" /> : <SortAsc className="h-4 w-4" />}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
                    {filteredApps.map((app) => (
                        <Card 
                            key={app.id}
                            className="hover:shadow-lg transition-all duration-300 cursor-pointer"
                            onClick={() => handleAppClick(app.id)}
                        >
                            <CardHeader>
                                <div className="flex items-center gap-4">
                                    <Avatar className="h-12 w-12 rounded-xl">
                                        {app.logo_url ? (
                                            <AvatarImage src={app.logo_url} alt={`${app.name} logo`} />
                                        ) : (
                                            <AvatarFallback className="bg-primary/10">
                                                <AppWindow className="h-6 w-6 text-primary" />
                                            </AvatarFallback>
                                        )}
                                    </Avatar>
                                    <h3 className="font-semibold text-lg line-clamp-2">{app.name}</h3>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <p className="text-muted-foreground mb-3 line-clamp-2">
                                    {app.user_description || 'No description'}
                                    {app.user_description && app.user_description.length > 100 && (
                                        <MoreHorizontal className="inline ml-1 h-4 w-4" />
                                    )}
                                </p>
                                <div className="text-xs text-muted-foreground flex flex-wrap gap-2">
                                    <span>By {app.created_by}</span>
                                    <Separator orientation="vertical" className="h-4" />
                                    <span>Created {moment.utc(app.created_date).fromNow()}</span>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </div>

                {filteredApps.length === 0 && (
                    <div className="text-center py-12 text-muted-foreground">
                        No apps found matching your criteria
                    </div>
                )}

                {filteredApps.length > 0 && (
                    <div className="flex justify-center gap-2 mt-8">
                        <Button
                            variant="outline"
                            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="outline"
                            onClick={() => setCurrentPage(prev => prev + 1)}
                            disabled={!hasMore}
                        >
                            Next
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserApps;