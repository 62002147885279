import React, { useState } from 'react';
import ComponentPreview from '../../preview/ComponentPreview';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import createAppSDK from '@/api/app-sdk/AppSDK';
import AppErrors from './AppError';


export default function AppDemo() {
    const location = useLocation();
    const { appId, appSlug, pageName } = useParams();
    const [appSDK, setAppSDK] = useState(null);
    const [errors, setAppErrors] = useState([]);
    

    const onAppError = (title, details, componentName) => {
        console.log("ON APP ERROR", title, details, componentName);
        // TODO: report to parent window
        setAppErrors(prevErrors => [...prevErrors, { title, details, componentName }]);
        window.parent?.postMessage({
            type: "app_error",
            error: { title, details, componentName }
        }, "*");

    }

    useEffect(() => {
        createAppSDK({
            id: appId,
            slug: appSlug,
            onError: onAppError
        }).then((appSDK) => {
            setAppSDK(appSDK);
        });
    }, [])

    useEffect(() => {
        window.parent.postMessage({ type: "app_changed_url", url: window.location.href }, '*');
        window.addEventListener('beforeinstallprompt', (e) => {
            e.prompt();
        });
      }, [location, pageName]);


      if (!appSDK) {
        return (
            <div className="fixed inset-0 flex items-center justify-center">
                <div className="flex flex-col items-center space-y-4">
                    <div className="w-12 h-12 border-4 border-gray-200 border-t-black rounded-full animate-spin"></div>
                </div>
            </div>
        );
      }


    

    const createComponentPreview = (componentCode, componentName, additionalComponentProps) => {
        const componentProps = {
            ...(additionalComponentProps || {})
        }
        return <ComponentPreview 
                    componentCode={componentCode}
                    componentName={componentName}
                    componentProps={componentProps}
                    appSDK={appSDK}
                    onError={onAppError}
                />
    }

    let mainContent = null;

    const currentPage = appSDK.getCurrentPage();

    if (!currentPage) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 bg-red-50 border border-red-200 rounded-lg">
                <div className="text-2xl font-medium text-red-600">404 Page Not Found</div>
                <div className="text-red-500">The page "{pageName}" could not be found in this application.</div>
            </div>
        );
    } else if (errors.length > 0) {
        mainContent = <div className="min-h-full flex flex-col max-w-4xl mx-auto">
            <AppErrors appErrors={errors} isFixing={false} />
        </div>
    } else if (appSDK.integrations.getMissingPackages().length > 0) {
        mainContent = (
            <div className="flex flex-col items-center justify-center p-8 space-y-4 text-center">
                <div className="text-2xl font-light text-gray-600">Additional Integrations Needed</div>
                <div className="text-gray-500">
                    This app requires the following integrations to be connected:
                    <div className="font-medium mt-2">{appSDK.integrations.getMissingPackages().join(', ')}</div>
                </div>
                <Link 
                    to="/integrations"
                    className="btn mt-4"
                >
                    Connect Integrations
                </Link>
            </div>
        );
    } else {
        mainContent = createComponentPreview(currentPage.code, currentPage.name);
    }

    let mainContentWithLayout = mainContent;
    if (appSDK.files.layout) {
        mainContentWithLayout = createComponentPreview(appSDK.files.layout, "Layout", {
            children: mainContent,
            currentPageName: pageName
        });
    }

    return (
        <div className="w-full h-full">
            <div className="flex flex-col w-full h-full" id="app-demo">            
                <div className="bg-white w-full min-h-full overflow-auto">
                    <div id="component-preview-container">
                        {mainContentWithLayout}
                    </div>
                    {
                        appSDK.files.globals_css && (
                            <style>{appSDK.files.globals_css}</style>
                        )
                    }
                </div>
            </div>
        </div>
    );
}