import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { Folder, FileCode, Database, Layout as LayoutIcon } from 'lucide-react';
import { useApp } from "@/context/AppContext";
import AppSidebar from '@/components/ui/app-sidebar';
import { useNavigate } from 'react-router-dom';

const AppIDE = () => {
  const { app } = useApp();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedContent, setSelectedContent] = useState('');
  const navigate = useNavigate();

  const getFileContent = (type, key) => {
    switch(type) {
      case 'pages':
        return app.pages[key] || '';
      case 'components': 
        return app.components[key] || '';
      case 'entities':
        return JSON.stringify(app.entities[key], null, 2) || '';
      case 'layout':
        return app.layout || '';
      default:
        return '';
    }
  };

  const handleFileSelect = (fileId) => {
    const [type, key] = fileId.split(':');
    setSelectedFile({ type, key });
    setSelectedContent(getFileContent(type, key));
  };

  const handleBack = () => {
    navigate(`/apps/${app.id}/editor/workspace/overview`);
  };

  const sidebarItems = [
    app.pages && Object.keys(app.pages).length > 0 && {
      id: 'pages',
      label: 'Pages',
      icon: <Folder className="w-4 h-4" />,
      subitems: Object.keys(app.pages).map(key => ({
        id: `pages:${key}`,
        label: key,
        icon: <FileCode className="w-4 h-4" />
      }))
    },
    app.components && Object.keys(app.components).length > 0 && {
      id: 'components',
      label: 'Components', 
      icon: <Folder className="w-4 h-4" />,
      subitems: Object.keys(app.components).map(key => ({
        id: `components:${key}`,
        label: key,
        icon: <FileCode className="w-4 h-4" />
      }))
    },
    app.entities && Object.keys(app.entities).length > 0 && {
      id: 'entities',
      label: 'Entities',
      icon: <Folder className="w-4 h-4" />,
      subitems: Object.keys(app.entities).map(key => ({
        id: `entities:${key}`,
        label: key,
        icon: <Database className="w-4 h-4" />
      }))
    },
    app.layout && {
      id: 'layout:main',
      label: 'Layout.js',
      icon: <LayoutIcon className="w-4 h-4" />
    }
  ].filter(Boolean);

  return (
    <div className="absolute inset-0 flex border rounded-lg overflow-hidden bg-white shadow-sm">
      <AppSidebar
        items={sidebarItems}
        selected={selectedFile ? `${selectedFile.type}:${selectedFile.key}` : null}
        onSelect={handleFileSelect}
        title="Files"
        showBackButton={true}
        onBack={handleBack}
      />

      <div className="flex-1 bg-white">
        {selectedFile ? (
          <Editor
            height="100%"
            defaultLanguage={selectedFile.type === 'entities' ? 'json' : 'javascript'}
            theme="vs"
            value={selectedContent}
            options={{
              minimap: { enabled: false },
              fontSize: 14,
              readOnly: true,
              scrollBeyondLastLine: false,
              fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace'
            }}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-gray-500">
            Select a file to view code
          </div>
        )}
      </div>
    </div>
  );
};

export default AppIDE;
