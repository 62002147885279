import createClient from '../axiosClient'

function createPackageClient(axiosClient, packageSchema, onError) {
    const packageClient = {};
    packageSchema.endpoints.forEach(endpoint => {
        packageClient[endpoint.name] = async (data) => {
            if (typeof data === 'string') {
                throw new Error(`Integration ${endpoint.name} must receive an object with named parameters, received: ${data}`);
            }
            try {
                let formData;
                let contentType;
                if (data instanceof FormData || (data && Object.values(data).some(value => value instanceof File))) {
                    formData = new FormData();
                    Object.keys(data).forEach(key => {
                        if (data[key] instanceof File) {
                            formData.append(key, data[key], data[key].name);
                        } else if (typeof data[key] === 'object' && data[key] !== null) {
                            formData.append(key, JSON.stringify(data[key]));
                        } else {
                            formData.append(key, data[key]);
                        }
                    });
                    contentType = 'multipart/form-data';
                } else {
                    formData = data;
                    contentType = 'application/json';
                }

                const isCorePackage = packageSchema.package_name === 'Core';
                const endpointPath = isCorePackage ? `/integrations/Core/${endpoint.name}` : `/integrations/installable/${packageSchema.package_name}/${packageSchema.instance_id}/integration-endpoints/${endpoint.name}`;

                const responsePromise = axiosClient.post(endpointPath, formData || data, {
                    headers: { 'Content-Type': contentType }
                });

                const response = await responsePromise;
                return response;
            } catch (error) {
                onError(
                    `Error calling integration ${endpoint.name}: ${error.response?.data?.detail || error.message}`,
                    `When using the app, the following integration call failed:\ninput parameters:\n${JSON.stringify(data, null, 2)}`,
                );
                throw error;
            }
        }
    });

    return packageClient;
}


export default async function createIntegrationsSDK(appId, onError) {
    const client = createClient('', {
        'X-App-Id': appId,
    });
    const packagesInfo = await client.get(`/apps/${appId}/integrations/schema`);
    let integrationsClient = {};
    packagesInfo.installed_packages.forEach(pkg => {
        integrationsClient[pkg.package_name] = createPackageClient(client, pkg, onError);
    });

    integrationsClient.getMissingPackages = () => {
        return packagesInfo.missing_packages;
    }
    return integrationsClient;
}
