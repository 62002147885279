import { entitiesFromApp } from './Entities';
import createIntegrationsSDK from './Integrations';
import createUsersSDK from './Users';
import createClient from '../axiosClient';

function _getCurrentPage(app) {
    let url = window.location.pathname;
    if (url.endsWith('/')) {
        url = url.slice(0, -1);
    }
    let urlLastPart = url.split('/').pop();
    if (urlLastPart.includes('?')) {
        urlLastPart = urlLastPart.split('?')[0];
    }

    // there could be multiple options: 
    // 1. the page name exists:
    if (Object.keys(app.pages).map(page => page.toLowerCase()).includes(urlLastPart.toLowerCase())) {
        return {
            name: urlLastPart,
            code: app.pages[urlLastPart]
        }
    } 
    // 2. the page name is the app slug or id or 'preview' - in that case, return the main page:
    if (urlLastPart === app.slug || urlLastPart === app.id || urlLastPart === 'preview') {
        return {
            name: Object.keys(app.pages)[0],
            code: app.pages[Object.keys(app.pages)[0]]
        }
    }

    return null;
}

const createPageUrl = (app, pageName, params) => {
    if (params && typeof params === 'object') {
      return `/apps-show/${app.slug}/${pageName}?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`;
    }
    if (params && typeof params === 'string') {
      if (params.startsWith('?')) {
        return `/apps-show/${app.slug}/${pageName}${params}`;
      }
      return `/apps-show/${app.slug}/${pageName}?${params}`;
    }
    return `/apps-show/${app.slug}/${pageName}`;
  }


export default async function createAppSDK(options) {
    const client = createClient('/apps/public',{
        'X-App-Id': options.id,
        'X-App-Slug': options.slug,
    });
    // has to have either id or slug:
    if (!options.id && !options.slug) {
        throw new Error('Either id or slug must be provided');
    }

    let app; 
    try {
        if (options.slug) {
            app = await client.get(`/prod/by-slug/${options.slug}`);
        } else {
            app = await client.get(`/prod/by-id/${options.id}`);
        }
    } catch (e) {
        if (e.response && e.response.status === 403) {
            let appId;
            if (options.slug) {
                const appInfo = await client.get(`/login-info/by-slug/${options.slug}`);
                appId = appInfo.id;
            } else {
                appId = options.id;
            }
            const nextUrl = window.location.href;
            window.location.href = `/apps/${appId}/login?nextUrl=${nextUrl}`;
        }
        throw e;
    }

    const onError = (title, details, componentName) => {
        if (!title || !details || !componentName) {
            console.log("no title, details or componentName", title, details, componentName);
        }
        // Extract component name from error details if it exists
        let actualComponentName = componentName;
        if (details && typeof details === 'string') {
            // Look for "at ComponentName" pattern
            const atMatch = details.match(/at\s+(\w+)\s+/);
            console.log(atMatch, "AT MATCH");
            if (atMatch && atMatch[1]) {
                const potentialName = atMatch[1];
                
                // Check if this name exists as a component or page
                if (app.components && app.components[potentialName]) {
                    actualComponentName = potentialName;
                } else if (app.pages && app.pages[potentialName]) {
                    actualComponentName = potentialName;
                } else {
                    // Check component/page code for "function PotentialName"
                    for (const key in app.components || {}) {
                        if (app.components[key].includes(`function ${potentialName}`)) {
                            actualComponentName = key;
                            break;
                        }
                    }
                    for (const key in app.pages || {}) {
                        if (app.pages[key].includes(`function ${potentialName}`)) {
                            actualComponentName = key;
                            break;
                        }
                    }
                }
            }
        }
        options.onError(title, details, actualComponentName);
    }


    const entitiesSDK = entitiesFromApp(app, onError);
    const integrationsSDK = await createIntegrationsSDK(app.id, onError);
    const usersSDK = createUsersSDK(app.id, onError);

    return {
        files: app,
        entities: entitiesSDK,
        integrations: integrationsSDK,
        users: usersSDK,
        getCurrentPage: () => _getCurrentPage(app),
        createPageUrl: (pageName, params) => createPageUrl(app, pageName, params),
        options: {
            ...options,
            onError: onError
        }
    }
}