import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import AuthAPI from "../../api/AuthAPI";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../components/ui/sheet";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Card, CardContent } from "../../components/ui/card";
import { ArrowUpDown, Search, Users as UsersIcon, UserPlus, UserCheck, UserX } from 'lucide-react';
import moment from "moment";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function UserManagement() {
    const { user } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: 'created_date', direction: 'desc' });
    const [stats, setStats] = useState({
        totalUsers: 0,
        newUsersToday: 0,
        premiumUsers: 0
    });
    const [userGrowthData, setUserGrowthData] = useState([]);
    
    useEffect(() => {
        loadUsers();
    }, []);

    useEffect(() => {
        if (users.length) {
            const today = moment().startOf('day');
            
            // Calculate basic stats
            setStats({
                totalUsers: users.length,
                newUsersToday: users.filter(u => moment(u.created_date).isAfter(today)).length,
                premiumUsers: users.filter(u => u.subscription_tier && u.subscription_tier !== 'free').length
            });

            // Calculate user growth data
            const dateGroups = users.reduce((acc, user) => {
                const date = moment(user.created_date).format('YYYY-MM-DD');
                acc[date] = (acc[date] || 0) + 1;
                return acc;
            }, {});

            // Convert to array and sort by date
            const growthData = Object.entries(dateGroups)
                .map(([date, count]) => ({
                    date,
                    users: count
                }))
                .sort((a, b) => moment(a.date).diff(moment(b.date)));

            setUserGrowthData(growthData);
        }
    }, [users]);

    const loadUsers = async () => {
        const response = await AuthAPI.listUsers();
        setUsers(response);
    };

    const handleUpdateUser = async (userId, attributes) => {
        setLoading(true);
        try {
            const response = await AuthAPI.updateUser(userId, attributes);
            setUsers(users.map(u => u.id === userId ? response : u));
            setSelectedUser(response);
        } catch (error) {
            console.error("Failed to update user:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (key) => {
        setSortConfig({
            key,
            direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        });
    };

    const filteredAndSortedUsers = users
        .filter(user => 
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (user.full_name && user.full_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            user.platform_role.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            if (sortConfig.key === 'created_date') {
                return sortConfig.direction === 'asc' 
                    ? new Date(a[sortConfig.key]) - new Date(b[sortConfig.key])
                    : new Date(b[sortConfig.key]) - new Date(a[sortConfig.key]);
            }
            return sortConfig.direction === 'asc'
                ? a[sortConfig.key] > b[sortConfig.key] ? 1 : -1
                : b[sortConfig.key] > a[sortConfig.key] ? 1 : -1;
        });

    if (!user.platform_role.includes('admin')) {
        return <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <UserX className="w-16 h-16 text-red-500 mx-auto mb-4" />
                <h2 className="text-2xl font-bold text-gray-800">Access Denied</h2>
                <p className="text-gray-600">You are not authorized to access this page</p>
            </div>
        </div>;
    }

    return (
        <div className="container mx-auto py-10 px-4">
            <div className="mb-8">
                <h1 className="text-3xl font-bold mb-6">User Management</h1>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                    <Card>
                        <CardContent className="flex items-center p-6">
                            <UsersIcon className="w-8 h-8 text-blue-500 mr-4" />
                            <div>
                                <p className="text-sm text-gray-600">Total Users</p>
                                <p className="text-2xl font-bold">{stats.totalUsers}</p>
                            </div>
                        </CardContent>
                    </Card>
                    
                    <Card>
                        <CardContent className="flex items-center p-6">
                            <UserPlus className="w-8 h-8 text-green-500 mr-4" />
                            <div>
                                <p className="text-sm text-gray-600">New Today</p>
                                <p className="text-2xl font-bold">{stats.newUsersToday}</p>
                            </div>
                        </CardContent>
                    </Card>
                    
                    <Card>
                        <CardContent className="flex items-center p-6">
                            <UserCheck className="w-8 h-8 text-yellow-500 mr-4" />
                            <div>
                                <p className="text-sm text-gray-600">Premium Users</p>
                                <p className="text-2xl font-bold">{stats.premiumUsers}</p>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                <Card className="mb-8">
                    <CardContent className="p-6">
                        <h3 className="text-lg font-semibold mb-4">User Growth</h3>
                        <div className="h-[200px] w-full">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={userGrowthData}>
                                    <XAxis 
                                        dataKey="date" 
                                        tickFormatter={(date) => moment(date).format('MMM DD')}
                                    />
                                    <YAxis />
                                    <Tooltip 
                                        labelFormatter={(date) => moment(date).format('MMMM DD, YYYY')}
                                        formatter={(value) => [`${value} new users`]}
                                    />
                                    <Line 
                                        type="monotone" 
                                        dataKey="users" 
                                        stroke="#2563eb" 
                                        strokeWidth={2}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>

                <div className="flex justify-between items-center mb-6">
                    <div className="relative w-72">
                        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
                        <Input
                            placeholder="Search users..."
                            className="pl-8"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
            </div>
            
            <div className="bg-white rounded-lg shadow">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="cursor-pointer" onClick={() => handleSort('email')}>
                                <div className="flex items-center">
                                    Email
                                    <ArrowUpDown className="ml-2 h-4 w-4" />
                                </div>
                            </TableHead>
                            <TableHead className="cursor-pointer" onClick={() => handleSort('full_name')}>
                                <div className="flex items-center">
                                    Full Name
                                    <ArrowUpDown className="ml-2 h-4 w-4" />
                                </div>
                            </TableHead>
                            <TableHead className="cursor-pointer" onClick={() => handleSort('platform_role')}>
                                <div className="flex items-center">
                                    Platform Role
                                    <ArrowUpDown className="ml-2 h-4 w-4" />
                                </div>
                            </TableHead>
                            <TableHead className="cursor-pointer" onClick={() => handleSort('created_date')}>
                                <div className="flex items-center">
                                    Created Date
                                    <ArrowUpDown className="ml-2 h-4 w-4" />
                                </div>
                            </TableHead>
                            <TableHead className="cursor-pointer" onClick={() => handleSort('subscription_tier')}>
                                <div className="flex items-center">
                                    Subscription Plan
                                    <ArrowUpDown className="ml-2 h-4 w-4" />
                                </div>
                            </TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {filteredAndSortedUsers.map((user) => (
                            <TableRow key={user.id} className="hover:bg-gray-50">
                                <TableCell className="font-medium">{user.email}</TableCell>
                                <TableCell>{user.full_name || '-'}</TableCell>
                                <TableCell>
                                    <span className={`px-2 py-1 rounded-full text-xs ${
                                        user.platform_role === 'platform_admin' 
                                            ? 'bg-purple-100 text-purple-800' 
                                            : 'bg-gray-100 text-gray-800'
                                    }`}>
                                        {user.platform_role}
                                    </span>
                                </TableCell>
                                <TableCell>{moment.utc(user.created_date).fromNow()}</TableCell>
                                <TableCell>
                                    <span className={`px-2 py-1 rounded-full text-xs ${
                                        user.subscription_tier === 'enterprise' 
                                            ? 'bg-yellow-100 text-yellow-800'
                                            : user.subscription_tier === 'pro'
                                            ? 'bg-green-100 text-green-800'
                                            : 'bg-gray-100 text-gray-800'
                                    }`}>
                                        {user.subscription_tier || 'Free'}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <Button 
                                        variant="outline"
                                        size="sm"
                                        onClick={() => setSelectedUser(user)}
                                    >
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <Sheet open={!!selectedUser} onOpenChange={() => setSelectedUser(null)}>
                <SheetContent className="sm:max-w-xl">
                    <SheetHeader>
                        <SheetTitle>Edit User</SheetTitle>
                    </SheetHeader>
                    
                    {selectedUser && (
                        <div className="space-y-6 py-6">
                            <div>
                                <h3 className="mb-2 font-medium">Email</h3>
                                <p className="text-gray-600">{selectedUser.email}</p>
                            </div>

                            <div>
                                <h3 className="mb-2 font-medium">Full Name</h3>
                                <Input
                                    type="text"
                                    value={selectedUser.full_name || ''}
                                    onChange={(e) => handleUpdateUser(selectedUser.id, { full_name: e.target.value })}
                                    disabled={loading}
                                />
                            </div>

                            <div>
                                <h3 className="mb-2 font-medium">Username</h3>
                                <p className="text-gray-600">{selectedUser.username || '-'}</p>
                            </div>

                            <div>
                                <h3 className="mb-2 font-medium">Phone Number</h3>
                                <Input
                                    type="text"
                                    value={selectedUser.phone_number || ''}
                                    onChange={(e) => handleUpdateUser(selectedUser.id, { phone_number: e.target.value })}
                                    disabled={loading}
                                />
                            </div>

                            <div>
                                <h3 className="mb-2 font-medium">Platform Role</h3>
                                <Select
                                    value={selectedUser.platform_role}
                                    onValueChange={(value) => 
                                        handleUpdateUser(selectedUser.id, { platform_role: value })
                                    }
                                    disabled={loading}
                                >
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="user">User</SelectItem>
                                        <SelectItem value="platform_admin">Platform Admin</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div>
                                <h3 className="mb-2 font-medium">Subscription Tier</h3>
                                <Select
                                    value={selectedUser.subscription_tier}
                                    onValueChange={(value) =>
                                        handleUpdateUser(selectedUser.id, { subscription_tier: value })
                                    }
                                    disabled={loading}
                                >
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="free">Free</SelectItem>
                                        <SelectItem value="builder">Builder</SelectItem>
                                        <SelectItem value="pro">Pro</SelectItem>
                                        <SelectItem value="enterprise">Enterprise</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                                <div>
                                    <h3 className="mb-2 font-medium">Stripe Customer ID</h3>
                                    <p className="text-sm text-gray-600 font-mono">{selectedUser.stripe_customer_id || '-'}</p>
                                </div>

                                <div>
                                    <h3 className="mb-2 font-medium">Stripe Subscription ID</h3>
                                    <p className="text-sm text-gray-600 font-mono">{selectedUser.stripe_subscription_id || '-'}</p>
                                </div>

                                <div>
                                    <h3 className="mb-2 font-medium">Stripe Subscription Status</h3>
                                    <p className="text-sm text-gray-600">{selectedUser.stripe_subscription_status || '-'}</p>
                                </div>

                                <div>
                                    <h3 className="mb-2 font-medium">API Key</h3>
                                    <p className="text-sm text-gray-600 font-mono break-all">{selectedUser.api_key || '-'}</p>
                                </div>

                                <div>
                                    <h3 className="mb-2 font-medium">Account Status</h3>
                                    <span className={`px-2 py-1 rounded-full text-xs ${
                                        selectedUser.disabled 
                                            ? 'bg-red-100 text-red-800' 
                                            : 'bg-green-100 text-green-800'
                                    }`}>
                                        {selectedUser.disabled ? 'Disabled' : 'Active'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </SheetContent>
            </Sheet>
        </div>
    );
}