import axios from 'axios'
import _ from 'lodash'
import { API_BASE_URL } from '../config'
import socketManager from './socket-manager'
import { toast } from 'react-hot-toast'

let defaultHeaders = {}

export function addDefaultHeader(key, value) {
    defaultHeaders[key] = value
}

export function removeDefaultHeader(key) {
	delete defaultHeaders[key]
}

let failCallbacks = []

export function addFailCallback(callback) {
    failCallbacks.push(callback)
}

export default function createClient(endpointBase, customHeaders = {}, failSilently = false) {
	const axiosClient = axios.create()
	axiosClient.defaults.baseURL = API_BASE_URL + endpointBase
	axiosClient.defaults.headers.common['Content-Type'] = 'application/json'
	axiosClient.defaults.headers.common['Accept'] = 'application/json'
	Object.keys(customHeaders).forEach(key => {
		axiosClient.defaults.headers.common[key] = customHeaders[key]
	});

	axiosClient.interceptors.request.use(
		(config) => {
            config.headers = _.merge(config.headers, defaultHeaders)
			config.headers['X-Socket-Id'] = socketManager.getSocketId();
			config.headers['X-Origin-URL'] = window.location.href;
			return config
		},
		(error) => {
			console.error("request error", error);
			return Promise.reject(error)
		},
	)

	axiosClient.interceptors.response.use(
		(response) => {
			return response.data;
		},
		(error) => {
			for (const callback of failCallbacks) {
				callback(error)
			}
			console.error("response error", error);
			if (!error.response) {
				console.log("NO ERROR RESPONSE", error);
			}
			else if (error.response?.status === 429) {
				if (!failSilently) {
					toast.error('Too many requests, please try again later.')
				}
			} else if (error.response?.status === 500) {
				if (error.response.data?.detail && !failSilently) {		
					let msgString = error.response.data.detail;
					if (msgString.length > 100){
						msgString = msgString.substring(0, 100) + '...'
					}
					toast.error('Error: ' + msgString)
				} else if (!failSilently) {
					toast.error('Error occured')
				}
			} else if (error.response?.status !== 403 && !failSilently) {
				toast.error('Error occured')
			}
			return Promise.reject(error);
		}
	);

	return axiosClient
}
