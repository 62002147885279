import { useApp } from "../../context/AppContext";
import Chat from "../../components/chat/Chat";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Progress } from "@/components/ui/progress";
import { Link } from "react-router-dom";
import { CreditCard, HelpCircle } from "lucide-react";

const ChatButtomRight = ({currentUsage, isDisabled}) => {
    const {app} = useApp();
    if (!currentUsage) return null;

    const monthlyUsagePercent = (currentUsage.monthly_usage / currentUsage.monthly_limit) * 100;
    const dailyUsagePercent = (currentUsage.daily_usage / currentUsage.daily_limit) * 100;
    const monthlyMessagesLeft = currentUsage.monthly_limit - currentUsage.monthly_usage;
    const dailyMessagesLeft = currentUsage.daily_limit - currentUsage.daily_usage;

    if (isDisabled) {
        return (
            <div className="flex items-center gap-2 text-xs text-red-500">
                <span>Out of messages for today</span>
                <Link 
                    to="/billing" 
                    className="flex items-center gap-1 text-blue-500 hover:text-blue-600"
                >
                    <CreditCard className="h-3 w-3" />
                    Upgrade
                </Link>
            </div>
        );
    }

    if (monthlyMessagesLeft < 1 || dailyMessagesLeft < 1) {
        return (
            <HoverCard>
                <HoverCardTrigger asChild>
                    <div className="flex items-center gap-2 text-xs text-gray-500 cursor-pointer">
                        <span>
                            {monthlyMessagesLeft <= dailyMessagesLeft 
                                ? `${monthlyMessagesLeft} messages left this month`
                                : `${dailyMessagesLeft} messages left today`
                            }
                        </span>
                    </div>
                </HoverCardTrigger>
                <HoverCardContent side="top" align="end" className="w-80 z-50">
                    <div className="space-y-3">
                        <div className="flex justify-between items-center">
                            <h4 className="text-sm font-semibold">Usage Limits</h4>
                            <span className="text-xs px-2 py-1 bg-gray-100 rounded-full">{currentUsage.tier} tier</span>
                        </div>
                        <div className="space-y-2">
                            <div>
                                <div className="flex justify-between text-xs mb-1">
                                    <span>Daily Usage</span>
                                    <span>{currentUsage.daily_usage}/{currentUsage.daily_limit}</span>
                                </div>
                                <Progress value={dailyUsagePercent} className="h-1" />
                            </div>
                            <div>
                                <div className="flex justify-between text-xs mb-1">
                                    <span>Monthly Usage</span>
                                    <span>{currentUsage.monthly_usage}/{currentUsage.monthly_limit}</span>
                                </div>
                                <Progress value={monthlyUsagePercent} className="h-1" />
                            </div>
                        </div>
                        <Link 
                            to="/billing" 
                            className="flex items-center gap-2 text-xs text-blue-500 hover:text-blue-600 mt-2"
                        >
                            <CreditCard className="h-3 w-3" />
                            Upgrade Plan
                        </Link>
                    </div>
                </HoverCardContent>
            </HoverCard>
        );
    }

    return (
        <HoverCard>
            <HoverCardTrigger asChild>
                <div className="flex items-center gap-2 text-xs text-gray-500 hover:text-gray-700 cursor-pointer">
                    <HelpCircle className="h-3 w-3" />
                    <span>Need support?</span>
                </div>
            </HoverCardTrigger>
            <HoverCardContent side="top" align="end" className="w-60 p-3 z-50">
                <div className="space-y-2">
                    <a 
                        href="https://discord.gg/hRvPbGCf"
                        target="_blank"
                        className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
                    >
                        <svg className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03z"/>
                        </svg>
                        Join our Discord
                    </a>
                    <a 
                        href={`mailto:app@base44.com?subject=i%20need%20help%20with%20my%20${app.name}%20app!`}
                        target="_blank"
                        className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900 transition-colors"
                    >
                        <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                        Email Support
                    </a>
                </div>
            </HoverCardContent>
        </HoverCard>
    );
}

export default function AppChat() {
    const { app, addChatMessage, editChatMessage, undoMessage, stopChat, suggestions, currentUsage } = useApp();
    const isDisabled = currentUsage ? 
        (currentUsage.daily_usage >= currentUsage.daily_limit || currentUsage.monthly_usage >= currentUsage.monthly_limit) 
        : false;

    return (
        <div className="h-full absolute w-full">
            <Chat 
                chat={app.conversation} 
                addMessage={addChatMessage} 
                status={app.status} 
                editMessage={editChatMessage} 
                undoMessage={undoMessage} 
                onStop={stopChat} 
                suggestions={suggestions}
                isDisabled={isDisabled}
                customButtomRight={<ChatButtomRight currentUsage={currentUsage} isDisabled={isDisabled} />}
            />
        </div>
    )
}