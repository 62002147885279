import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Rocket } from 'lucide-react';
import { API_BASE_URL } from '../config';
import logoImage from '../logo_v2.png';

export default function Login() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('access_token');
    if (accessToken) {
      localStorage.setItem('token', accessToken);
      const navigateTo = searchParams.get('from_url') || '/';
      window.location.href = navigateTo;
    }
  }, [location]);

  const handleGoogleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get('from_url') || '/';
    window.location.href = `${API_BASE_URL}/auth/login?from_url=${fromUrl}`;
  };

  

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-md overflow-hidden flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 p-8 md:p-12">
          <img src={logoImage} alt="base44 logo" className="w-24 h-24 mx-auto mb-8" />
          <h1 className="text-3xl font-semibold text-center text-gray-800 mb-4">Welcome to base44</h1>
          <p className="text-center text-gray-600 mb-8">Sign in with your Google account to get started.</p>
          <button 
            onClick={handleGoogleLogin} 
            className="w-full flex items-center justify-center gap-3 bg-white text-gray-800 px-6 py-3 rounded-full border border-gray-300 hover:bg-gray-50 hover:shadow-md transition-all duration-200 font-medium text-base"
          >
            <img height="20" width="20" src="https://cdn.simpleicons.org/google" alt="Google logo" />
            <span>Continue with Google</span>
          </button>
        </div>
        <div className="w-full md:w-1/2 bg-gray-100 p-8 md:p-12 flex flex-col justify-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Just Build Stuff.</h2>
          <ul className="text-gray-600 space-y-4">
            <li className="flex items-center"><Rocket className="mr-2 text-gray-500" size={18} /> AI generated apps</li>
            <li className="flex items-center"><Rocket className="mr-2 text-gray-500" size={18} /> Easy to customize</li>
            <li className="flex items-center"><Rocket className="mr-2 text-gray-500" size={18} /> Seamless deployment</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
