import { Send, Link, Stars, Square, Loader } from 'lucide-react';
import { useRef, useState } from 'react';
import Files from '../../api/Files';
import { useMediaQuery } from 'react-responsive';

export default function ChatTextArea({value, files = [], onSend, placeholder, disabled, isProcessing, onValueChange, onFilesChange, onStop, customButtomRight}) {
    const fileInputRef = useRef();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [uploadingFiles, setUploadingFiles] = useState([]);

    const handleSend = () => {
        onSend({
            text: value,
            files: files
        });
        onValueChange('');
        onFilesChange([]);
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
        if (e.key === 'v' && e.ctrlKey) {
            handlePaste(e);
        }
    }

    const handlePaste = async (e) => {
        console.log("paste");
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (let item of items) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                await uploadFile(file);
            }
        }
    }

    const uploadFile = async (file) => {
        try {
            setUploadingFiles(prev => [...prev, file.name]);
            const response = await Files.uploadFile(file);
            console.log('uploaded file', response);
            onFilesChange([...files, response.url]);
            setUploadingFiles(prev => prev.filter(f => f !== file.name));
        } catch (error) {
            console.error('Failed to upload file:', error);
            setUploadingFiles(prev => prev.filter(f => f !== file.name));
        }
    }

    const handleFileUpload = async (e) => {
        console.log("upload file");
        const files = Array.from(e.target.files);
        for (let file of files) {
            await uploadFile(file);
        }
        fileInputRef.current.value = '';
    }

    const handleDrop = async (e) => {
        console.log("drop file");
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        for (let file of files) {
            await uploadFile(file);
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    }

    const removeFile = (fileUrl) => {
        onFilesChange(files.filter(url => url !== fileUrl));
    }

    return (
        <div 
            className='w-full relative shadow-sm border border-gray-200 rounded-lg bg-white/80 backdrop-blur'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            {(files.length > 0 || uploadingFiles.length > 0) && (
                <div className="flex flex-wrap gap-4 p-4 border-b">
                    {files.map((fileUrl, index) => (
                        <div key={index} className="relative">
                            {fileUrl.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                                <div className="relative group">
                                    <img 
                                        src={fileUrl} 
                                        alt="Attached content"
                                        className="max-w-[200px] rounded-lg border border-gray-200 shadow-sm"
                                    />
                                    <button 
                                        onClick={() => removeFile(fileUrl)}
                                        className="absolute top-1 right-1 bg-white/80 text-gray-500 hover:text-gray-700 rounded-full p-1"
                                    >
                                        ×
                                    </button>
                                </div>
                            ) : (
                                <div className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200 group relative">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-500">
                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                                        <polyline points="14 2 14 8 20 8"/>
                                    </svg>
                                    <span className="truncate max-w-[150px]">{fileUrl.split('/').pop()}</span>
                                    <button 
                                        onClick={() => removeFile(fileUrl)}
                                        className="text-gray-500 hover:text-gray-700"
                                    >
                                        ×
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                    {uploadingFiles.map((fileName, index) => (
                        <div key={index} className="flex items-center gap-2 p-2 bg-white rounded-lg border border-gray-200">
                            <Loader className="w-4 h-4 animate-spin text-gray-500" />
                            <span className="truncate max-w-[150px] text-gray-500 text-sm">
                                Uploading {fileName}...
                            </span>
                        </div>
                    ))}
                </div>
            )}
            <textarea
                value={value}
                onChange={(e) => onValueChange(e.target.value)}
                onKeyDown={onKeyDown}
                onPaste={handlePaste}
                placeholder={placeholder}
                disabled={disabled}
                className="w-full pl-4 pt-4 pr-16 text-sm resize-none overflow-auto focus:outline-none bg-transparent rounded-t-lg"
                style={{
                    height: 'auto',
                    minHeight: '76px',
                    maxHeight: '400px'
                }}
                onInput={e => {
                    e.target.style.height = 'auto';
                    if (e.target.scrollHeight > 400) {
                        e.target.style.height = '400px';
                    } else {
                        e.target.style.height = e.target.scrollHeight + 'px';
                    }
                }}
            />
            {(value || isProcessing) && (
                <button 
                    onClick={isProcessing ? onStop : handleSend}
                    className="absolute flex justify-center items-center top-4 right-4 p-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-[34px] h-[34px] transition-colors"
                >
                    {isProcessing ? (
                        <Square className="w-4 h-4" />
                    ) : (
                        <Send className="w-4 h-4" />
                    )}
                </button>
            )}

            <div className="flex justify-between text-sm p-4 pt-2">
                <div className="flex gap-1 items-center">
                    <input 
                        ref={fileInputRef}
                        type="file" 
                        id="chat-file-upload"
                        className="hidden"
                        multiple
                        onChange={handleFileUpload}
                        accept=".jpg,.jpeg,.png,.gif,.pdf,.txt,.doc,.docx"
                    />
                    <button 
                        onClick={() => document.getElementById('chat-file-upload').click()}
                        className="flex items-center hover:bg-gray-100 rounded-md p-1 text-gray-500"
                        title="Upload files"
                    >
                        <Link className="w-4 h-4" />
                    </button>
                    {/* <button
                        onClick={handleEnhancePrompt}
                        className="flex items-center hover:bg-gray-100 rounded-md p-1 text-blue-500"
                        title="Enhance prompt"
                    >
                        <Stars className="w-5 h-5" />
                        <span className="ml-1.5">Enhance</span>
                    </button> */}
                </div>
                {value && !isMobile && <div className="flex items-center gap-1 text-xs text-gray-500">
                    Press <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Shift</kbd> + <kbd className="px-1 py-0.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">Enter</kbd> for new line
                </div>}
                {!value && customButtomRight && <div className="flex items-center gap-1 text-xs text-gray-500">
                    {customButtomRight}
                </div>}
            </div>
        </div>
    );
}