import { useState } from "react";
import { AlertCircle } from "lucide-react";

export default function AppErrors({ appErrors }) {
    const [showDetails, setShowDetails] = useState(false);
    console.log("appErrors", appErrors);
    return (
        <div className="flex flex-col items-center w-full max-w-xl mx-auto p-4">
            <div className="bg-white border-2 border-red-300 rounded-lg shadow-lg p-6 mb-4 w-full">
                <div className="flex items-center mb-4">
                    <AlertCircle className="w-8 h-8 text-red-500 mr-3" />
                    <div>
                        <h3 className="text-xl font-bold text-gray-800">Oops! Something went wrong</h3>
                        <p className="text-gray-600">Don't worry, our AI can help fix this issue</p>
                    </div>
                </div>

                <button
                    onClick={() => setShowDetails(!showDetails)}
                    className="w-full mb-4 text-gray-600 hover:text-gray-800 text-sm font-medium py-2 px-4 rounded-md bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                >
                    {showDetails ? 'Hide Technical Details' : 'Show Technical Details'}
                </button>

                {showDetails && (
                    <div className="bg-gray-50 rounded-md p-4 mb-4">
                        <div className="font-medium text-gray-700 mb-2">Technical Details</div>
                        <div className="space-y-2">
                            {appErrors.map((err, idx) => (
                                <div key={idx} className="text-sm font-mono bg-gray-100 p-3 rounded text-gray-700 whitespace-pre-wrap">
                                    <div className="font-bold">{err.title}</div>
                                    <div>{err.details}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}