import React from 'react';
import { Button } from "./button";
import { X, ChevronDown, ChevronLeft } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./collapsible";

const SidebarItem = ({ item, selected, onSelect }) => {
  if (item.subitems) {
    return (
      <Collapsible defaultOpen className="group/collapsible">
        <CollapsibleTrigger className="flex items-center w-full px-4 py-2 text-sm font-medium hover:bg-gray-200 rounded-md transition-colors">
          {item.icon}
          <span className="ml-2">{item.label}</span>
          <ChevronDown className="ml-auto h-4 w-4 transition-transform group-data-[state=open]/collapsible:rotate-180" />
        </CollapsibleTrigger>
        <CollapsibleContent className="pl-4 space-y-1">
          {item.subitems.map((subitem, index) => (
            <Button
              key={index}
              variant={selected === subitem.id ? "secondary" : "ghost"}
              className={`w-full justify-start ${
                selected === subitem.id 
                  ? "bg-primary/10 text-primary hover:bg-primary/20" 
                  : "hover:bg-gray-100"
              }`}
              onClick={() => onSelect(subitem.id)}
            >
              {subitem.icon}
              <span className="ml-2">{subitem.label}</span>
            </Button>
          ))}
        </CollapsibleContent>
      </Collapsible>
    );
  }

  return (
    <Button
      variant={selected === item.id ? "secondary" : "ghost"}
      className={`w-full justify-start ${
        selected === item.id 
          ? "bg-primary/10 text-primary hover:bg-primary/20"
          : "hover:bg-gray-100"
      }`}
      onClick={() => onSelect(item.id)}
    >
      {item.icon}
      <span className="ml-2">{item.label}</span>
    </Button>
  );
};

const AppSidebar = ({ 
  items, 
  selected, 
  onSelect, 
  isOpen, 
  onClose,
  title,
  showBackButton,
  onBack
}) => {
  return (
    <>
      {/* Mobile overlay */}
      <div 
        className={`fixed inset-0 bg-black/50 transition-opacity lg:hidden ${
          isOpen ? 'opacity-100 z-40' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onClose}
      />

      {/* Sidebar */}
      <div
        className={`fixed lg:static inset-y-0 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 transition duration-200 ease-in-out bg-gray-100 h-full overflow-y-auto border-r z-50 lg:z-0 w-56`}
      >
        <div className="flex justify-between items-center p-4 lg:hidden">
          <h2 className="font-semibold">{title || "Menu"}</h2>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>

        {showBackButton && (
          <Button
            variant="ghost"
            className="w-full justify-start mb-2 px-4"
            onClick={onBack}
          >
            <ChevronLeft className="h-4 w-4 mr-2" />
            Back to Editor
          </Button>
        )}

        <nav className="space-y-1 p-2">
          {items.map((item, index) => (
            <SidebarItem 
              key={index} 
              item={item} 
              selected={selected} 
              onSelect={onSelect} 
            />
          ))}
        </nav>
      </div>
    </>
  );
};

export default AppSidebar; 