import React from 'react';
import { useApp } from '../../../context/AppContext';
import { useToast } from '@/hooks/use-toast';
import { Play, Share2, Star, Globe, Lock, AlertCircle } from 'lucide-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import EditInPlace from '../../../components/EditInPlace';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip";

export default function AppOverview() {
    const { app, updateApp } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const appLink = `/apps-show/${app.slug}`;

    const handleOpenApp = () => {
        navigate(`/apps/${app.id}/editor/preview/${Object.keys(app.pages)[0]}`);
    };

    const handleShareApp = () => {
        const fullAppLink = `${window.location.origin}${appLink}`;
        navigator.clipboard.writeText(fullAppLink);
        toast({
            title: "Success",
            description: "App link copied to clipboard"
        });
    };

    const handleAppNameChange = async (newName) => {
        if (app.status?.state !== "ready") {
            toast({
                title: "Error", 
                description: "App is still loading... name not updated",
                variant: "destructive"
            });
            return;
        }
        if (newName.length === 0) {
            toast({
                title: "Error",
                description: "App name cannot be empty",
                variant: "destructive"
            });
            return;
        }
        updateApp({name: newName});
    };

    const handleToggleStar = () => {
        updateApp({ is_starred: !app.is_starred });
        toast({
            title: app.is_starred ? "Removed from favorites" : "Added to favorites",
            description: app.is_starred ? "App removed from your starred list" : "App added to your starred list"
        });
    };

    const handleTogglePublic = () => {
        if (app.integrations_used.overall_is_using_integrations) {
            toast({
                title: "Cannot make public",
                description: "Apps using integrations must remain private for security",
                variant: "destructive"
            });
            return;
        }
        updateApp({ setting_is_public: !app.setting_is_public });
        toast({
            title: app.setting_is_public ? "App is now private" : "App is now public",
            description: app.setting_is_public ? "Only you can access this app" : "Anyone with the link can access this app"
        });
    };

    const renderIntegrationsList = () => {
        const { pages, components } = app.integrations_used;
        if (!Object.keys(pages).length && !Object.keys(components).length) return null;

        const totalIntegrations = new Set([
            ...Object.values(pages).flat(),
            ...Object.values(components).flat()
        ]).size;

        const tooltipContent = (
            <div className="max-w-xs">
                {Object.keys(pages).length > 0 && (
                    <div className="mb-2">
                        <p className="font-semibold mb-1">Pages:</p>
                        {Object.entries(pages).map(([page, integrations]) => (
                            <div key={page} className="ml-2">
                                {page}: {integrations.join(', ')}
                            </div>
                        ))}
                    </div>
                )}
                {Object.keys(components).length > 0 && (
                    <div>
                        <p className="font-semibold mb-1">Components:</p>
                        {Object.entries(components).map(([component, integrations]) => (
                            <div key={component} className="ml-2">
                                {component}: {integrations.join(', ')}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );

        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div className="mt-2 text-sm text-muted-foreground">
                            {totalIntegrations} integration{totalIntegrations !== 1 ? 's' : ''} used
                        </div>
                    </TooltipTrigger>
                    <TooltipContent side="bottom" className="p-4">
                        {tooltipContent}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        );
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <div className="lg:p-6">
                <div className="flex items-center mb-6">
                    {app.logo_url && (
                        <img
                            src={app.logo_url}
                            alt={`${app.name} logo`}
                            className="w-20 h-20 rounded-lg object-cover mr-6 border"
                        />
                    )}
                    <div className="flex-grow">
                        <div className="flex items-center justify-between">
                            <div>
                                <h1 className="text-3xl font-bold mb-2">
                                    <EditInPlace initialText={app.name} onChange={handleAppNameChange} />
                                </h1>
                                <p className="text-sm text-muted-foreground">Created {moment.utc(app.created_date).fromNow()}</p>
                            </div>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={handleToggleStar}
                                className={`hover:bg-yellow-100 transition-colors ${app.is_starred ? 'text-yellow-500' : 'text-gray-400'}`}
                            >
                                <Star className={`h-5 w-5 ${app.is_starred ? 'fill-current' : ''}`} />
                            </Button>
                        </div>
                        <p className="text-muted-foreground mt-2">{app.user_description}</p>
                        {renderIntegrationsList()}
                    </div>
                </div>

                <div className="flex gap-4 mb-8">
                    <Button onClick={handleOpenApp} variant="default" size="sm">
                        <Play className="h-4 w-4 mr-2" />
                        Open App
                    </Button>
                    <Button onClick={handleShareApp} variant="outline" size="sm">
                        <Share2 className="h-4 w-4 mr-2" />
                        Share App
                    </Button>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <span>
                                    <Button 
                                        onClick={handleTogglePublic} 
                                        variant="outline" 
                                        size="sm"
                                        disabled={app.integrations_used.overall_is_using_integrations}
                                    >
                                        {(!app.setting_is_public || app.integrations_used.overall_is_using_integrations) ? (
                                            <>
                                                <Lock className="h-4 w-4 mr-2" />
                                                Private
                                                {app.integrations_used.overall_is_using_integrations && (
                                                    <AlertCircle className="h-4 w-4 ml-2 text-amber-500" />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Globe className="h-4 w-4 mr-2" />
                                                Public
                                            </>
                                        )}
                                    </Button>
                                </span>
                            </TooltipTrigger>
                            {app.integrations_used.overall_is_using_integrations && (
                                <TooltipContent>
                                    <p>Apps using integrations must remain private for security reasons</p>
                                </TooltipContent>
                            )}
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </div>
        </div>
    );
}
