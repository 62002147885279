import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import AppLogsAPI from '@/api/AppLogsAPI';
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { CalendarIcon, ChevronDown, ChevronUp, Loader2, XCircle } from "lucide-react";
import { cn } from "@/lib/utils";
import BarChartDiv from '@/components/BarChartDiv';
import { Link } from 'react-router-dom';

export default function AppAnalytics({ appId, pageNames }) {
  const [stats, setStats] = useState(null);
  const [date, setDate] = useState({
    from: undefined,
    to: undefined,
  });
  const [selectedPage, setSelectedPage] = useState('all');
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAllUsers, setShowAllUsers] = useState(false);

  const fetchStats = async () => {
    setIsLoading(true);
    try {
      const params = {};
      if (date?.from) {
        params.date_from = format(date.from, 'yyyy-MM-dd');
      }
      if (date?.to) {
        params.date_to = format(date.to, 'yyyy-MM-dd');
      }
      if (selectedPage !== 'all') {
        params.page_name = selectedPage;
      }
      if (selectedUser) {
        params.user_email = selectedUser;
      }
      const response = await AppLogsAPI.getStats(appId, params);
      setStats(response);
    } catch (error) {
      console.error('Failed to fetch stats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [appId, date, selectedPage, selectedUser]);

  const filteredTopPages = !stats ? [] : selectedPage === 'all' 
    ? stats.top_pages 
    : stats.top_pages.filter(page => page.url === selectedPage);

  const barChartData = filteredTopPages.map(page => ({
    name: page.page_name,
    value: page.visits
  }));

  const renderPageLabel = (item) => {
    if (appId === "all") {
      return <Link to={`/apps/${item.name}`} target='_blank' className="hover:underline">{item.name}</Link>;
    }
    return item.name;
  };

  const displayedUsers = stats?.top_users ? (
    showAllUsers ? stats.top_users : stats.top_users.slice(0, 5)
  ) : [];

  const hasMoreUsers = stats?.top_users?.length > 5;

  if (isLoading && !stats) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  return (
    <div className="space-y-4 p-4">
      <div className="flex flex-col sm:flex-row gap-3">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full sm:w-[240px] justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={1}
            />
          </PopoverContent>
        </Popover>

        <Select value={selectedPage} onValueChange={setSelectedPage}>
          <SelectTrigger className="w-full sm:w-[240px]">
            <SelectValue placeholder="Filter by page" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Pages</SelectItem>
            {pageNames?.map(pageName => (
              <SelectItem key={pageName} value={pageName}>
                {pageName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {selectedUser && (
          <div className="flex items-center gap-2 bg-muted px-3 py-2 rounded-md">
            <span className="text-sm">Filtered by user: {selectedUser}</span>
            <button 
              onClick={() => setSelectedUser(null)}
              className="text-muted-foreground hover:text-foreground"
            >
              <XCircle className="h-4 w-4" />
            </button>
          </div>
        )}

        {isLoading && (
          <div className="flex items-center gap-2">
            <Loader2 className="h-4 w-4 animate-spin text-muted-foreground" />
            <span className="text-sm text-muted-foreground">Updating...</span>
          </div>
        )}
      </div>

      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg">Total Unique Users</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{stats?.unique_users_count || 0}</div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-lg">Top Users</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {displayedUsers.map((user, i) => (
                <div 
                  key={i} 
                  className="flex justify-between items-center text-sm cursor-pointer hover:bg-muted p-2 rounded-md"
                  onClick={() => setSelectedUser(user.email)}
                >
                  <span className="truncate max-w-[180px]">{user.email}</span>
                  <span className="font-semibold">{user.visits} visits</span>
                </div>
              ))}
              {hasMoreUsers && (
                <Button
                  variant="ghost"
                  className="w-full mt-2 text-sm text-muted-foreground hover:text-foreground"
                  onClick={() => setShowAllUsers(!showAllUsers)}
                >
                  {showAllUsers ? (
                    <><ChevronUp className="h-4 w-4 mr-2" /> Show Less</>
                  ) : (
                    <><ChevronDown className="h-4 w-4 mr-2" /> Show More Users</>
                  )}
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Top Pages</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mb-4">
            Shows the most visited pages in your app and their total visit count
          </p>
          <BarChartDiv 
            data={barChartData}
            title=""
            maxWidth="100%"
            maxLabelWidth="60%"
            backgroundColor="transparent"
            renderLabel={renderPageLabel}
          />
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Unique Users Over Time</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mb-4">
            Tracks the number of distinct users accessing your app each day
          </p>
          {stats?.users_per_date.length <= 1 ? (
            <div className="h-[200px] flex items-center justify-center text-muted-foreground">
              Not enough data to show trends
            </div>
          ) : (
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={stats.users_per_date}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Area 
                    type="monotone" 
                    dataKey="unique_users" 
                    stroke="#8884d8" 
                    fill="#8884d8" 
                    name="Unique Users"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle className="text-lg">Total Usage Over Time</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mb-4">
            Shows the total number of visits to your app, including repeat visits from the same users
          </p>
          {stats?.users_per_date.length <= 1 ? (
            <div className="h-[200px] flex items-center justify-center text-muted-foreground">
              Not enough data to show trends
            </div>
          ) : (
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={stats.users_per_date}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Area 
                    type="monotone" 
                    dataKey="total_visits" 
                    stroke="#82ca9d" 
                    fill="#82ca9d" 
                    name="Total Visits"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
