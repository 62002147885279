import React, { createContext, useState, useContext, useEffect } from 'react';
import UserAppAPI from '../api/UserAppAPI';
import UsageLogsAPI from '../api/UsageLogsAPI';
import { useParams, useNavigate } from 'react-router-dom';
import { entitiesFromApp } from '../api/app-sdk/Entities';
import createIntegrationsClient from '../api/app-sdk/Integrations';
import RegisterToApp from '../components/user-app/RegisterToApp';
import _ from 'lodash';
import { Loader2 } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alert';
import AppLogsAPI from '../api/AppLogsAPI';
import createAppSDK from '../api/app-sdk/AppSDK';
import AppLogin from '../components/user-app/AppLogin';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
    const {appId, pageName} = useParams();
    const [app, setApp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integrationsSDK, setIntegrationsSDK] = useState(null);
    const [entitiesSDK, setEntitiesSDK] = useState(null);
    const [error, setError] = useState(null);
    const [appErrors, setAppErrors] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(null);
    const [suggestions, setSuggestions] = useState(null);
    const [currentUsage, setCurrentUsage] = useState(null);


    const onAppError = (title, details, componentName) => {
      let error;
      if (_.isObject(title)) {
        console.log("onAppError title is object: ", title);
        error = {
          title: title.title || title.message || title.toString(),
          details: title.details || title.stack || title.toString(),
          componentName: componentName || pageName
        };
      } else {
        error = {
          title,
          details,
          componentName: componentName || pageName
        };
      }
        setAppErrors(prevErrors => {
            if (!prevErrors.some(e => e.title === error.title)) {
              return [...prevErrors, error];
            } else {
              // Check if we need to update the componentName
              const existingError = prevErrors.find(e => e.title === error.title);
              console.log("FOUND DOUBLE ERROR", error, existingError, app);
              if (existingError && app) {
                  // Priority 1: Check components
                  if (app.components && app.components[error.componentName] && !app.components[existingError.componentName]) {
                      existingError.componentName = error.componentName;
                  }
                  // Priority 2: Check pages
                  else if (app.pages && app.pages[error.componentName] && !app.pages[existingError.componentName] && !app.components[existingError.componentName]) {
                      existingError.componentName = error.componentName;
                  }
              }
            }
            return prevErrors;
        });
    }
    

    const init = async () => {
        try {
            setIsLoading(true);
            let _appId = appId;
            // const appSDK = await createAppSDK({
            //   id: _appId,
            //   slug: appSlug,
            //   onError: onAppError
            // });
            
            await UserAppAPI.fetchAndSubscribe(_appId, (fetchedApp) => {
                console.log("RECEIVED AN APP UPDATE");
                setApp(fetchedApp);
                const appEntitiesSDK = entitiesFromApp(fetchedApp, onAppError);
                setEntitiesSDK(appEntitiesSDK);
                setAppErrors([]);
            });
            
            // Load everything in parallel
            const [integClientPromise, usagePromise] = await Promise.all([
                createIntegrationsClient(_appId, onAppError),
                UsageLogsAPI.getCurrentUsage()
            ]);
            // Set state with resolved values
            setIntegrationsSDK(integClientPromise);
            setCurrentUsage(usagePromise);
            refreshSuggestions(_appId);

        } catch (err) {
            if (err.response && err.response.status === 403) {
                const nextUrl = window.location.href;
                window.location.href = `/apps/${appId}/login?nextUrl=${nextUrl}`;
            } else {
                setError('Failed to load app: ' + err.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    
    useEffect(() => {
      if (!app || (appId && app?.id !== appId)) {
        init();
      }
    }, [appId]);

  const refreshCurrentUsage = async () => {
    const usage = await UsageLogsAPI.getCurrentUsage();
    setCurrentUsage(usage);
  }

  let currentPage = null;
  if (pageName) {
    if (app?.pages[pageName]) {
      currentPage = {
        name: pageName,
        code: app?.pages[pageName]
      }
    } else {
      currentPage = null
    } 
  } else if (app?.pages) {
    currentPage = {
      name: Object.keys(app?.pages)[0],
      code: app?.pages[Object.keys(app?.pages)[0]]
    }
  }

  useEffect(() => {
    if (app?.id && currentPage?.name) {
      AppLogsAPI.logUserInApp(app.id, currentPage.name);
    }
  }, [app?.id, currentPage?.name]);

  const addChatMessage = async (message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
        app.conversation.messages.push({...message, role: 'user'});
        const updatedApp = await UserAppAPI.addMessage(appId, {...message, additional_message_params: params});
        setApp(updatedApp);
        if (app.app_stage !== 'pending') {
          refreshSuggestions();
        }
        await refreshCurrentUsage();
    } catch (err) {
        console.error(err);
        setError('Failed to add message: ' + err.message);
    }
  };

  const editChatMessage = async (messageId, message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
      let origMsg = app.conversation.messages.find(msg => msg.id === messageId);
      _.assign(origMsg, message);
      const updatedApp = await UserAppAPI.editMessage(appId, messageId, {...message, additional_message_params: params});
      setApp(updatedApp);
      refreshSuggestions();
      await refreshCurrentUsage();
    } catch (err) {
      console.error(err);
      setError('Failed to edit message: ' + err.message);
    }
  };

  const undoMessage = async (messageId) => {
    try {
      const updatedApp = await UserAppAPI.undoMessage(appId, messageId);
      setApp(updatedApp);
    } catch (err) {
      console.error(err);
      setError('Failed to undo message: ' + err.message);
    }
  }

  const refreshSuggestions = async (_appId) => {
    // only refresh suggestions in editor
    if (!window.location.href.includes('/editor/'))  {
      return;
    }
    // const suggestions = await UserAppAPI.getSuggestions(app?.id || _appId);
    // setSuggestions(suggestions);
}

  const updateApp = async (props) => {
    const updatedApp = await UserAppAPI.update(app.id, props);
    setAppErrors([])
    setApp(updatedApp);
  }

  const stopChat = async () => {
    const updatedApp = await UserAppAPI.stopChat(app.id);
    setApp(updatedApp);
  }

  let element = children

  if (showRegister) {
    element = <AppLogin nextUrl={window.location.href} />;
    } else if (isLoading) {
        element = <div className="fixed inset-0 flex justify-center items-center">
            <Loader2 className="h-8 w-8 animate-spin" />
        </div>;
    } else if (error) {
        // element = <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
    } else if (!app) {
        element = <Alert variant="destructive"><AlertDescription>App not found</AlertDescription></Alert>;
    }


  return (
    <AppContext.Provider value={{
        app,
        setApp,
        updateApp,
        isLoading,
        integrationsSDK,
        entitiesSDK,
        error,
        appErrors,
        setAppErrors,
        onAppError,
        addChatMessage,
        undoMessage,
        currentPage,
        editChatMessage,
        stopChat,
        suggestions,
        currentUsage
      }}>
        {element}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);