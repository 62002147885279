import React, { createElement, isValidElement } from 'react';
import { transform as _transform } from 'sucrase';

export const transform = (code) => {
  const transformed = _transform(code, {
    transforms: ['jsx', 'typescript', 'imports'],
    production: false,
    sourceMapOptions: { compiledFilename: 'UserCode.jsx' },
    filePath: 'UserCode.jsx'

    // sourceMap: true
  });

  return {
    code: transformed.code.substring(13), // remove leading `"use strict";`
    sourceMap: transformed.sourceMap
  }
}

const firstStatementRegexp =
  /^(\s*)(<[^>]*>|function[(\s]|\(\)[\s=]|class\s)(.*)/

const normalizeCode = (code) => {
  return code.replace(firstStatementRegexp, '$1export default $2$3');
}

const evalCode = (code, scope) => {
  // `default` is not allowed in `new Function`
  const { default: _, import: imports, ...rest } = scope
  const finalScope = { React, require: createRequire(imports), ...rest }
  const scopeKeys = Object.keys(finalScope);
  const scopeValues = scopeKeys.map((key) => finalScope[key]);
  const fn = new Function(...scopeKeys, code);
  return fn(...scopeValues);
}

const createRequire =
  (imports = {}) =>
  (module) => {
    if (!imports.hasOwnProperty(module)) {
      const modulePath = module.split('/');
      if (imports.hasOwnProperty(modulePath[0])) {
        let obj = imports[modulePath[0]];
        for (let i = 1; i < modulePath.length; i++) {
          if (obj.hasOwnProperty(modulePath[i])) {
            obj = obj[modulePath[i]];
          } else {
            throw new Error(`Module not found: '${module}'`);
          }
        }
        return obj;
      }
      throw new Error(`Module not found: '${module}'`);
    }
    return imports[module];
  }


export const compileModule = (code, scope) => {
  if (!code.trim()) return null;
  const exports = {};
  const render = (value) => {
    exports.default = value;
  }
  const { code: transformedCode, sourceMap } = transform(normalizeCode(code));
  evalCode(transformedCode, { render, ...scope, exports });
  let newExports = {};
  Object.keys(exports).forEach((key) => {
    if (typeof exports[key] === 'function' && !isValidElement(exports[key])){
      newExports[key] = (props) => createElement(exports[key], props || {});
    } else {
      newExports[key] = exports[key];
    }
  });

  // if there's no default and only one function:
  if (!newExports.default && Object.keys(newExports).length === 1) {
    newExports.default = newExports[Object.keys(newExports)[0]];
  }
  return newExports;
}


export const compileElement = (code, scope) => {
  const exports = compileModule(code, scope);
  const result = exports.default;
  if (!result) return null;
  if (isValidElement(result)) return result;
  
  if (typeof result === 'function') return result;
  if (typeof result === 'string') {
    return result;
  }
  return null;
}


