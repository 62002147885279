import React, { useEffect, useState } from 'react';
import BillingAPI from '@/api/BillingAPI';
import UsageLogsAPI from '@/api/UsageLogsAPI';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useAuth } from '@/context/AuthContext';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Progress
} from 'recharts';

const STRIPE_PUBLISHABLE_KEY = 'pk_live_51QK05lD5KgnLQ3Da11GxDxTfiDHUUr9QGgmU8BY0JIRuCUkU01wxJoKJ8wP72hPAS30JxEsTdEJ4puysUKwQn2te00tAMQjxt9';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default function Billing() {
  const { toast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsData = await UsageLogsAPI.getStats();
        setStats(statsData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast({
          variant: "destructive",
          title: "Error", 
          description: "Failed to load billing information",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubscribe = async (priceId) => {
    try {
      const response = await BillingAPI.createCheckoutSession(priceId);
      const { sessionId } = response;
      
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId
      });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to create checkout session. Please try again later.",
      });
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  const totalMessages = stats?.usage_by_date?.reduce((acc, day) => acc + day.total_messages, 0) || 0;
  
  // Get monthly message limit based on subscription
  let monthlyMessageLimit = 50; // Free tier default
  if (stats?.subscription_status === 'active' && stats?.stripe_subscription?.plan?.metadata?.monthly_message_limit) {
    monthlyMessageLimit = parseInt(stats.stripe_subscription.plan.metadata.monthly_message_limit);
  }

  const usagePercentage = (totalMessages / monthlyMessageLimit) * 100;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 min-h-screen flex flex-col max-w-8xl">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-12 text-gray-900">Billing & Usage</h1>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 mb-8 sm:mb-12">
        {/* Usage Progress */}
        <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-800">Current Usage</h2>
          <div className="space-y-4">
            <div className="flex flex-col gap-2">
              <div className="flex justify-between text-xs sm:text-sm text-gray-600">
                <span>Monthly Messages Used</span>
                <span>{totalMessages} / {monthlyMessageLimit}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className={`h-2.5 rounded-full ${usagePercentage > 90 ? 'bg-red-500' : usagePercentage > 75 ? 'bg-yellow-500' : 'bg-blue-500'}`}
                  style={{ width: `${Math.min(usagePercentage, 100)}%` }}
                ></div>
              </div>
              <div className="flex flex-col sm:flex-row justify-between text-xs sm:text-sm text-gray-600 gap-2">
                <span>Messages Remaining: {Math.max(0, monthlyMessageLimit - totalMessages)}</span>
                {/* <span>Status: {stats?.subscription_status || 'inactive'}</span> */}
              </div>
            </div>
          </div>
        </div>

        {/* Usage Chart */}
        <div className="bg-white rounded-xl shadow-sm p-4 sm:p-6">
          <h2 className="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-800">Daily Usage</h2>
          <div className="h-[160px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats?.usage_by_date || []}>
                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                <XAxis dataKey="_id" angle={-45} textAnchor="end" height={50} fontSize={10} stroke="#6b7280" />
                <YAxis fontSize={10} stroke="#6b7280" />
                <Tooltip />
                <Bar dataKey="total_messages" fill="url(#colorGradient)" radius={[4, 4, 0, 0]} />
                <defs>
                  <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#3b82f6" stopOpacity={0.8}/>
                    <stop offset="100%" stopColor="#3b82f6" stopOpacity={0.4}/>
                  </linearGradient>
                </defs>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Plans */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6">
        {/* Free Plan */}
        <div className="bg-white rounded-xl shadow-lg p-4 flex flex-col border border-gray-100 hover:border-blue-500 transition-all duration-200">
          <div className="text-center mb-6 sm:mb-8">
            <span className="inline-block px-4 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800 mb-3">Free</span>
            <div className="flex items-baseline justify-center">
              <span className="text-4xl sm:text-5xl font-bold text-gray-900">$0</span>
              <span className="text-gray-500 font-medium ml-2">/mo</span>
            </div>
          </div>
          <div className="space-y-4 mb-6 sm:mb-8 flex-grow">
            <div className="flex items-center text-sm sm:text-base text-gray-600">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>5 messages/day</span>
            </div>
            <div className="flex items-center text-sm sm:text-base text-gray-600">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>50 messages/month</span>
            </div>
          </div>
          <Button 
            className={`w-full h-10 sm:h-12 text-xs sm:text-sm font-medium ${
              !stats?.subscription_status ? 'bg-green-50 text-green-700 hover:bg-green-100' : 'bg-blue-600 hover:bg-blue-700 text-white'
            }`}
            disabled={!stats?.subscription_status}
          >
            {!stats?.subscription_status ? 'Current Plan' : 'Free Plan'}
          </Button>
        </div>

        {/* Paid Plans */}
        {stats?.pricing && Object.entries(stats.pricing).map(([tier, plan]) => (
          <div key={tier} className={`bg-white rounded-xl shadow-lg p-4 flex flex-col border border-gray-100 hover:border-blue-500 transition-all duration-200 ${
            stats?.subscription_status === 'active' && stats?.stripe_subscription?.plan?.id === plan.id ? 'border-blue-500' : ''
          }`}>
            <div className="text-center mb-6 sm:mb-8">
              <span className="inline-block px-4 py-1 rounded-full text-sm font-medium bg-blue-50 text-blue-700 mb-3">{plan.nickname}</span>
              <div className="flex items-baseline justify-center">
                <span className="text-4xl sm:text-5xl font-bold text-gray-900">${(plan.unit_amount / 100).toFixed(0)}</span>
                <span className="text-gray-500 font-medium ml-2">/mo</span>
              </div>
            </div>
            <div className="space-y-4 mb-6 sm:mb-8 flex-grow">
              <div className="flex items-center text-sm sm:text-base text-gray-600">
                <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                </svg>
                <span>No daily limits</span>
              </div>
              {plan.metadata?.features?.split(',').map((feature, index) => (
                <div key={index} className="flex items-center text-sm sm:text-base text-gray-600">
                  <svg className="w-4 h-4 sm:w-5 sm:h-5 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span>{feature.trim().replace(/_/g, ' ')}</span>
                </div>
              ))}
            </div>
            <Button 
              className={`w-full h-10 sm:h-12 text-xs sm:text-sm font-medium ${
                stats?.subscription_status === 'active' && stats?.stripe_subscription?.plan?.id === plan.id
                  ? 'bg-green-50 text-green-700 hover:bg-green-100'
                  : 'bg-blue-600 hover:bg-blue-700 text-white'
              }`}
              onClick={() => handleSubscribe(plan.id)}
              disabled={stats?.subscription_status === 'active' && stats?.stripe_subscription?.plan?.id === plan.id}
            >
              {stats?.subscription_status === 'active' && stats?.stripe_subscription?.plan?.id === plan.id
                ? 'Current Plan'
                : `Subscribe to ${plan.nickname}`}
            </Button>
          </div>
        ))}

        {/* Enterprise Plan */}
        <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-xl p-4 flex flex-col text-white">
          <div className="text-center mb-6 sm:mb-8">
            <span className="inline-block px-4 py-1 rounded-full text-sm font-medium bg-white/10 text-white mb-3">Enterprise</span>
            <div className="flex items-baseline justify-center">
              <span className="text-xl sm:text-2xl font-bold">Custom Pricing</span>
            </div>
          </div>
          <div className="space-y-4 mb-6 sm:mb-8 flex-grow">
            <div className="flex items-center text-sm sm:text-base text-gray-300">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>Unlimited messages</span>
            </div>
            <div className="flex items-center text-sm sm:text-base text-gray-300">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>Custom integrations</span>
            </div>
            <div className="flex items-center text-sm sm:text-base text-gray-300">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>Premium support</span>
            </div>
            <div className="flex items-center text-sm sm:text-base text-gray-300">
              <svg className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span>Dedicated architect</span>
            </div>
          </div>
          <Button 
            className="w-full h-10 sm:h-12 text-xs sm:text-sm font-medium bg-white text-gray-900 hover:bg-gray-100"
            onClick={() => window.location.href = 'mailto:sales@base44.ai'}
          >
            Contact Sales
          </Button>
        </div>
      </div>
    </div>
  );
}
