import { useState, useEffect } from 'react';
import UserAppAPI  from '../../api/UserAppAPI';
import { toast } from 'react-hot-toast';
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Loader2 } from "lucide-react";
import { useNavigate } from 'react-router-dom';

export default function AppLogin({ nextUrl }) {
    const navigate = useNavigate();
    const [appInfo, setAppInfo] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchAppInfo = async () => {
            try {
                if (nextUrl.includes('/apps/')) {
                    const appId = nextUrl.split('/apps/')[1].split('/')[0];
                    const info = await UserAppAPI.getLoginInfoById(appId);
                    setAppInfo(info);
                } else if (nextUrl.includes('/apps-show/')) {
                    const slug = nextUrl.split('/apps-show/')[1].split('/')[0];
                    const info = await UserAppAPI.getLoginInfoBySlug(slug);
                    setAppInfo(info);
                }
            } catch (error) {
                console.error('Failed to fetch app info:', error);
                toast.error('Failed to load app information');
            }
        };

        if (nextUrl) {
            fetchAppInfo();
        }
    }, [nextUrl]);

    const register = async () => {
        setLoading(true);
        const response = await UserAppAPI.registerUser(appInfo.id);
        if (nextUrl) {
            window.location.href = nextUrl
        } else {
            navigate(`/apps/${appInfo.id}`)
        }
        setLoading(false);
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-white to-gray-50">
            <Card className="max-w-md w-full shadow-lg border-0">
                <CardContent className="pt-8 pb-8 px-8 flex flex-col items-center text-center">
                    {appInfo?.logo_url && (
                        <Avatar className="h-28 w-28 mb-8 shadow-md hover:shadow-lg transition-shadow duration-200">
                            <AvatarImage src={appInfo.logo_url} alt={`${appInfo.name} logo`} />
                            <AvatarFallback className="bg-gradient-to-br from-teal-50 to-orange-50 text-teal-600 text-xl font-medium">
                                {appInfo.name?.[0]}
                            </AvatarFallback>
                        </Avatar>
                    )}
                    <h1 className="text-3xl font-semibold mb-4 bg-gradient-to-r from-teal-600 to-orange-500 bg-clip-text text-transparent">
                        Welcome to {appInfo?.name}
                    </h1>
                    <p className="mb-8 text-gray-600 leading-relaxed">
                        {appInfo?.user_description}
                    </p>
                    <Button 
                        size="lg"
                        className="bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-md hover:shadow-lg"
                        onClick={register}
                        disabled={loading}
                    >
                        {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                        {loading ? 'Accessing...' : 'Continue to App'}
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
}