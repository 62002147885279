import React, { useState } from 'react';
import { Globe, Copy, Trash2, ExternalLink } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { useApp } from '../../../context/AppContext';
import UserAppAPI from '../../../api/UserAppAPI';
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";

export default function AppSettings() {
    const { app } = useApp();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [isCloningApp, setIsCloningApp] = useState(false);

    const handleAppPublish = async () => {
        try {
            setIsLoadingPublish(true);
            if (app.app_publish_info === null) {
                await UserAppAPI.publishApp(app.id);
                toast({
                    title: "Success",
                    description: "App published successfully"
                });
            } else {
                await UserAppAPI.unpublishApp(app.id);
                toast({
                    title: "Success",
                    description: "App unpublished successfully"
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update app",
                variant: "destructive"
            });
        } finally {
            setIsLoadingPublish(false);
        }
    };

    const handleCloneApp = async () => {
        try {
            setIsCloningApp(true);
            const newApp = await UserAppAPI.cloneApp(app.id);
            toast({
                title: "Success",
                description: "App cloned successfully"
            });
            navigate(`/apps/${newApp.id}/editor/preview`);
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to clone app",
                variant: "destructive"
            });
        } finally {
            setIsCloningApp(false);
        }
    };

    const showDeleteConfirm = () => {
        if (window.confirm('Are you sure you want to delete this app? This action cannot be undone.')) {
            handleDeleteApp();
        }
    };

    const handleDeleteApp = async () => {
        try {
            await UserAppAPI.delete(app.id);
            toast({
                title: "Success",
                description: "App deleted successfully"
            });
            navigate('/');
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to delete app",
                variant: "destructive"
            });
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-8 space-y-8">
            <h2 className="text-3xl font-bold">App Settings</h2>

            <Card className="p-6">
                <h3 className="text-xl font-semibold mb-6">App Publication</h3>
                <div className="space-y-4">
                    {app.app_publish_info === null ? (
                        <Button 
                            onClick={handleAppPublish} 
                            disabled={isLoadingPublish}
                        >
                            <Globe className="mr-2 h-4 w-4" />
                            {isLoadingPublish ? "Publishing..." : "Publish to Marketplace"}
                        </Button>
                    ) : (
                        <>
                            <div className="flex items-center gap-2 text-green-600">
                                <Globe className="h-5 w-5" />
                                <p className="font-medium">Published in Marketplace</p>
                            </div>
                            <Button 
                                onClick={handleAppPublish} 
                                disabled={isLoadingPublish}
                                variant="destructive"
                            >
                                {isLoadingPublish ? "Unpublishing..." : "Unpublish App"}
                            </Button>
                        </>
                    )}
                </div>
            </Card>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <Card className="p-6">
                    <h3 className="text-xl font-semibold mb-6">Clone App</h3>
                    <Button 
                        onClick={handleCloneApp} 
                        disabled={isCloningApp}
                        className="w-full"
                    >
                        <Copy className="mr-2 h-4 w-4" />
                        {isCloningApp ? "Creating Copy..." : "Create Copy"}
                    </Button>
                </Card>

                <Card className="p-6 border-2 border-destructive/20">
                    <h3 className="text-xl font-semibold mb-6 text-destructive">Danger Zone</h3>
                    <Button 
                        onClick={showDeleteConfirm} 
                        variant="destructive"
                        className="w-full"
                    >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete App
                    </Button>
                </Card>
            </div>
        </div>
    );
}
