
// this is a helper for admins
export function appToChatActions(app) {
    let actions = [];
    // Start action group
    actions.push(`<action_group title="Create ${app.name}">`);

    // Add entities
    Object.keys(app.entities).forEach(entity => {
        const entityAsPrettyJson = JSON.stringify(app.entities[entity], null, 2);
        actions.push(
            `<action type="file" title="Create ${entity} entity" filePath="entities/${entity}.js">${entityAsPrettyJson}</action>`
        );
    });
    
    

    // Add layout if exists
    if (app.layout) {
        actions.push(
            `<action type="file" title="Create a layout for the app" filePath="Layout.js">${app.layout}</action>`
        );
    }

    // Add pages
    Object.keys(app.pages).forEach(pageName => {
        actions.push(
            `<action type="file" title="Create ${pageName} page" filePath="pages/${pageName}.js">${app.pages[pageName]}</action>`
        );
    });

    
    Object.keys(app.components).forEach(componentName => {
        actions.push(
            `<action type="file" title="Create ${componentName} component" filePath="components/${componentName}.js">${app.components[componentName]}</action>`
        );
    });
    

    // Close action group
    actions.push('</action_group>');

    return actions.join('\n');
}


export function appToXMLExample(app) {
    return `{% raw %}<user_ask>Create an app like ${app.name}</user_ask>\n\n<assistant>\n\n${appToChatActions(app)}\n\n</assistant>{% endraw %}`;
}
