import React from 'react';
import moment from 'moment';
import * as momentArabic from 'moment/locale/ar';
import * as momentFrench from 'moment/locale/fr';
import * as momentSpanish from 'moment/locale/es';
import * as momentHebrew from 'moment/locale/he';

import * as ReactHotToast from 'react-hot-toast';
import * as Recharts from 'recharts';
import * as LucideReact from 'lucide-react';
import * as ReactQuill from 'react-quill';
import * as ReactLeaflet from 'react-leaflet';
import 'leaflet/dist/leaflet.css'

import JsonSchemaForm from '../../lib/components/json-schema-form';
import JsonSchemaTable from '../../lib/components/JsonSchemaTable';
import CodeEditor from '../CodeEditor';
// import * as DndKit from '@dnd-kit/core';
// import * as DndKitSortable from '@dnd-kit/sortable';
// import * as DndKitUtilities from '@dnd-kit/utilities';
import * as DndKit from '@hello-pangea/dnd';
import * as ReactHookForm from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';

// Import all shadcn components
import * as Accordion from '@/components/ui/accordion';
import * as Alert from '@/components/ui/alert';
import * as AlertDialog from '@/components/ui/alert-dialog';
import * as AspectRatio from '@/components/ui/aspect-ratio';
import * as Avatar from '@/components/ui/avatar';
import * as Badge from '@/components/ui/badge';
import * as Button from '@/components/ui/button';
import * as Calendar from '@/components/ui/calendar';
import * as Card from '@/components/ui/card';
import * as Checkbox from '@/components/ui/checkbox';
import * as Collapsible from '@/components/ui/collapsible';
import * as Command from '@/components/ui/command';
import * as ContextMenu from '@/components/ui/context-menu';
import * as Dialog from '@/components/ui/dialog';
import * as DropdownMenu from '@/components/ui/dropdown-menu';
import * as Form from '@/components/ui/form';
import * as HoverCard from '@/components/ui/hover-card';
import * as Input from '@/components/ui/input';
import * as Label from '@/components/ui/label';
import * as Menubar from '@/components/ui/menubar';
import * as NavigationMenu from '@/components/ui/navigation-menu';
import * as Popover from '@/components/ui/popover';
import * as Progress from '@/components/ui/progress';
import * as RadioGroup from '@/components/ui/radio-group';
import * as ScrollArea from '@/components/ui/scroll-area';
import * as Select from '@/components/ui/select';
import * as Separator from '@/components/ui/separator';
import * as Sheet from '@/components/ui/sheet';
import * as Skeleton from '@/components/ui/skeleton';
import * as Slider from '@/components/ui/slider';
import * as Switch from '@/components/ui/switch';
import * as Table from '@/components/ui/table';
import * as Tabs from '@/components/ui/tabs';
import * as Textarea from '@/components/ui/textarea';
import * as Toast from '@/components/ui/toast';
import * as Toggle from '@/components/ui/toggle';
import * as Tooltip from '@/components/ui/tooltip';
import * as ToastHook from '@/hooks/use-toast';
import * as Toaster from '@/components/ui/toaster';
import * as Utils from '@/lib/utils';
import * as dateFns from 'date-fns';
import * as dateFnsLocalization from 'date-fns/locale';
import * as Lodash from 'lodash';

import * as ReactRouterDom from 'react-router-dom';
// import all addons
import * as THREE from 'three';
import * as ThreeOrbitControls from 'three/addons/controls/OrbitControls.js';
import * as ThreeGLTFLoader from 'three/addons/loaders/GLTFLoader.js';

import * as FramerMotion from 'framer-motion';
import * as CanvasConfetti from 'canvas-confetti';
import * as Html2Canvas from 'html2canvas';


import { compileElement } from './codeEval';

moment.locale('en-US');


export function getPackages() {
    return {
        // built in components
        '@/components/ui/json-schema-form': JsonSchemaForm,
        '@/components/ui/json-schema-table': JsonSchemaTable,
        '@/components/ui/code-editor': CodeEditor,

        // built in packages
        'recharts': Recharts,
        'react-hot-toast': ReactHotToast,
        'moment': moment,
        'moment/locale/he': momentHebrew,
        'moment/locale/ar': momentArabic,
        'moment/locale/fr': momentFrench,
        'moment/locale/es': momentSpanish,
        'react': React,
        'lucide-react': LucideReact,
        'react-quill': ReactQuill,
        // '@dnd-kit/core': DndKit,
        // '@dnd-kit/sortable': DndKitSortable,
        // '@dnd-kit/utilities': DndKitUtilities,
        '@hello-pangea/dnd': DndKit,
        'date-fns': dateFns,
        'date-fns/locale': dateFnsLocalization,
        'three': THREE,
        'three/addons/controls/OrbitControls.js': ThreeOrbitControls,
        'three/addons/loaders/GLTFLoader.js': ThreeGLTFLoader,
        
        'lodash': Lodash,
        'framer-motion': FramerMotion,
        'react-router-dom': ReactRouterDom,
        'react-hook-form': ReactHookForm,
        'react-quill/dist/quill.snow.css': {},
        'react-leaflet': ReactLeaflet,
        'leaflet/dist/leaflet.css': {},
        'canvas-confetti': CanvasConfetti,
        'html2canvas': Html2Canvas,

        // shadcn components
        '@/lib/utils': Utils,
        '@/components/ui/accordion': Accordion,
        '@/components/ui/alert': Alert,
        '@/components/ui/alert-dialog': AlertDialog,
        '@/components/ui/aspect-ratio': AspectRatio,
        '@/components/ui/avatar': Avatar,
        '@/components/ui/badge': Badge,
        '@/components/ui/button': Button,
        '@/components/ui/calendar': Calendar,
        '@/components/ui/card': Card,
        '@/components/ui/checkbox': Checkbox,
        '@/components/ui/collapsible': Collapsible,
        '@/components/ui/command': Command,
        '@/components/ui/context-menu': ContextMenu,
        '@/components/ui/dialog': Dialog,
        '@/components/ui/dropdown-menu': DropdownMenu,
        '@/components/ui/form': Form,
        '@/components/ui/hover-card': HoverCard,
        '@/components/ui/input': Input,
        '@/components/ui/label': Label,
        '@/components/ui/menubar': Menubar,
        '@/components/ui/navigation-menu': NavigationMenu,
        '@/components/ui/popover': Popover,
        '@/components/ui/progress': Progress,
        '@/components/ui/radio-group': RadioGroup,
        '@/components/ui/scroll-area': ScrollArea,
        '@/components/ui/select': Select,
        '@/components/ui/separator': Separator,
        '@/components/ui/sheet': Sheet,
        '@/components/ui/skeleton': Skeleton,
        '@/components/ui/slider': Slider,
        '@/components/ui/switch': Switch,
        '@/components/ui/table': Table,
        '@/components/ui/tabs': Tabs,
        '@/components/ui/textarea': Textarea,
        '@/components/ui/toast': Toast,
        '@/hooks/use-toast': ToastHook,
        // duplicate, model confuses sometimes
        '@/components/ui/use-toast': ToastHook,
        '@/components/ui/toaster': Toaster,
        '@/components/ui/toggle': Toggle,
        '@/components/ui/tooltip': Tooltip,
        
      }
}







export function generateElementFunction(componentCode, imports, globals) {
    const scope = {
      import: {
        ...getPackages(),
        ...(imports || {}),
      },
      ...(globals || {}),
    }

    console.log("imports", imports);

    return compileElement(componentCode, scope);
}