import AppAnalytics from '@/components/user-app/AppAnalytics';

function AdminAnalytics() {
    // Pass "all" as the appId to get analytics across all apps
    // Don't pass pageNames since we're looking at all apps
    return (
        <div className="container mx-auto py-6">
            <h1 className="text-2xl font-bold mb-6">Platform Analytics</h1>
            <AppAnalytics appId="all" />
        </div>
    );
}

export default AdminAnalytics;

