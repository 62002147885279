import React, { useState } from 'react';
import CodeEditor from '../../../components/CodeEditor';
import { useAuth } from "../../../context/AuthContext";
import { useApp } from '../../../context/AppContext';
import { API_BASE_URL } from '../../../config';
import { Copy } from 'lucide-react';

export function AppAPI() {
    const { user } = useAuth();
    const { app } = useApp();
    const [isCopied, setIsCopied] = useState(false);
    const entityName = Object.keys(app.entities).length ? Object.keys(app.entities)[0] : 'EntityName';

    const exampleCode = `import requests

# Your app ID
app_id = '${app.id}'

# API endpoint
base_url = f'${API_BASE_URL}'

def make_api_request(api_path, method='GET', data=None):
    url = f'{base_url}/{api_path}'
    headers = {
        'api_key': '${user.api_key}',
        'Content-Type': 'application/json'
    }

    # Make the request
    if method.upper() == 'GET':
        response = requests.request(method, url, headers=headers, params=data)
    else:
        response = requests.request(method, url, headers=headers, json=data)
    response.raise_for_status()
    return response.json()

entities = make_api_request(f'apps/{app_id}/entities/${entityName}')
print(entities)
`;

    const handleCopyClick = () => {
        navigator.clipboard.writeText(exampleCode);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl font-bold mb-4">
                🚀 API Integration Made Easy
            </h2>
            <h3 className="text-xl mb-2">
                Example: Listing Entities
            </h3>
            <p className="mb-4">
                Here's an example of how to use your API key to list entities:
            </p>
            <div className="relative">
                <CodeEditor
                    value={exampleCode}
                    language="python"
                    readOnly={true}
                    height="600px"
                />
                <button
                    onClick={handleCopyClick}
                    className="btn btn-sm btn-outline absolute top-2 right-2"
                >
                    <Copy className="w-4 h-4 mr-2" />
                    {isCopied ? 'Copied!' : 'Copy'}
                </button>
            </div>
        </div>
    );
}

export default AppAPI;