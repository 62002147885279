import React from 'react';

const BarChartDiv = ({ 
  data,
  title = 'Bar Chart',
  barColor = '#14b8a6',
  maxWidth = '800px',
  maxLabelWidth = '40%',
  backgroundColor = 'white',
  textColor = '#1f2937',
  renderLabel = (item) => item.name,
  renderValue = (item) => item.value
}) => {
  const maxValue = Math.max(...data.map(item => item.value));
  const barCount = data.length;

  // Adjust bar height based on number of bars
  const getBarHeight = () => {
    if (barCount <= 5) return '40px';
    if (barCount <= 10) return '32px'; 
    if (barCount <= 20) return '24px';
    return '16px';
  };

  // Adjust font size based on number of bars
  const getFontSize = () => {
    if (barCount <= 5) return '14px';
    if (barCount <= 10) return '12px';
    if (barCount <= 20) return '10px'; 
    return '8px';
  };

  const barHeight = getBarHeight();
  const fontSize = getFontSize();

  return (
    <div style={{
      width: '100%',
      maxWidth,
      margin: '0 auto',
      padding: '24px',
      backgroundColor,
      borderRadius: '8px',
      overflow: 'hidden'
    }}>
      {title && (
        <h2 style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '24px',
          color: textColor,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          {title}
        </h2>
      )}

      <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        {data.map((item, index) => {
          const percentage = (item.value / maxValue) * 100;
          
          return (
            <div key={index} style={{
              position: 'relative',
              height: barHeight,
              marginBottom: '8px',
              overflow: 'hidden'
            }}>
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: `${percentage}%`,
                backgroundColor: barColor,
                borderRadius: '4px',
                transition: 'width 0.5s ease-in-out'
              }} />
              
              <div style={{
                position: 'absolute',
                left: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                fontSize: fontSize,
                fontWeight: '500',
                color: textColor,
                zIndex: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: maxLabelWidth
              }}>
                {renderLabel(item)}
              </div>

              <div style={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                fontSize: fontSize,
                fontWeight: '500',
                color: textColor,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {renderValue(item)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BarChartDiv;
