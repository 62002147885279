import React from 'react';
import AppSidebar from '@/components/ui/app-sidebar';

const EditorSidebar = ({ items, selected, onSelect, isOpen, onClose }) => {
  return (
    <AppSidebar
      items={items}
      selected={selected}
      onSelect={onSelect}
      isOpen={isOpen}
      onClose={onClose}
      title="Editor"
    />
  );
};

export default EditorSidebar;
